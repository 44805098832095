import React, { useEffect, useState, useCallback, useRef } from "react";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toInteger } from "lodash";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import share from "../Assets/Images/sharevoter.svg";
import print from "../Assets/Images/print.svg";
import searchimg from "../Assets/Images/search.svg";

export default function VotersByAge() {
  const navigate = useNavigate();
  const [pollingAddress, setPollingAddress] = useState("");
  const [boothAddress, setBoothAddress] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [fromAge, setFromAge] = useState("");
  const [toAge, setToAge] = useState("");
  const [boothAddressList, setBoothAddressList] = useState([]);
  const [pollingAddressList, setPollingAddressList] = useState([]);
  const [candidateName, setCandidateName] = useState("");
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [fromAgeError, setFromAgeError] = useState("");
  const [toAgeError, setToAgeError] = useState("");
  const [fromBoothNumber, setfromBoothNumber] = useState(0);
  const [toBoothNumber, settoBoothNumber] = useState(0);
  const [toPollingAddress, setToPollingAddress] = useState("");
  const [fromPollingAddress, setFromPollingAddress] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [ageType, setAgeType] = useState("");
  const [genderType, setGenderType] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getBoothAddresses(decoded.UserName);
      getPollingBoothList(decoded.UserName);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    let candidateName = decoded.UserName;
    getvoterinfobyage(candidateName, 1, true);
  }, [candidateName]);

  const getBoothAddresses = (candidateName) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getboothaddress?candidateName=${"Jayashritai"}`,
    };

    axios(config)
      .then((response) => {
        let boothAddressList = response.data.response;
        setBoothAddressList(boothAddressList);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  const onFromNameChange = (e) => {
    setFromAge(e.target.value);
  };

  const onToNameChange = (e) => {
    setToAge(e.target.value);
  };

  const checkValidation = () => {
    let returnValue = true;
    setFromAgeError("");
    setToAgeError("");

    if (ageType === "") {
      if (fromAge == null || fromAge === "") {
        setFromAgeError("Please enter from age");
        returnValue = false;
      } else if (fromAge < 18) {
        setFromAgeError("Please enter an age greater than or equal to 18.");
        returnValue = false;
      }

      if (toAge == null || toAge === "") {
        setToAgeError("Please enter to age");
        returnValue = false;
      }
    }

    return returnValue;
  };

  const getvoterinfobyage = (candidateName, pageno, replace) => {
    // e.preventDefault();
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);
    const data = {
      boothAddress: boothAddress,
      pollingAddress: pollingAddress,
      fromValue: toInteger(fromAge),
      toValue: toInteger(toAge),
      isGetAllValue: true,
      searchPageNumber: pageno,
      searchResultCount: 10,
      FromBoothNumber: fromBoothNumber,
      ToBoothNumber: toBoothNumber,
      gender: genderType,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getvoterinfobyage?candidateName=${candidateName}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
        }
        setShowVoterInfo(true);
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };

  const onSearchClick = (pageno, replace) => {
    // if (checkValidation()) {
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);
    const data = {
      boothAddress: boothAddress,
      pollingAddress: pollingAddress,
      fromValue: toInteger(fromAge),
      toValue: toInteger(toAge),
      isGetAllValue: true,
      searchPageNumber: pageno,
      searchResultCount: 10,
      FromBoothNumber: fromBoothNumber,
      ToBoothNumber: toBoothNumber,
      gender: genderType,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getvoterinfobyage?candidateName=${candidateName}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
        }
        setShowVoterInfo(true);
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
    // }
  };
  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      onSearchClick(nextPageNumber, false);
    }
  };

  const onSelectFromAddressOfPolling = (boothNo, address) => {
    setfromBoothNumber(boothNo);
    setFromPollingAddress(address);
  };
  const onSelectToAddressOfPolling = (boothNo, address) => {
    settoBoothNumber(boothNo);
    setToPollingAddress(address);
  };

  const getPollingBoothList = (candidateNm) => {
    setVotersListLoading(true);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetDropDownValue?candidateName=${candidateNm}`,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          setPollingAddressList(response.data.response);
        }
      })
      .catch((error) => {
        console.log(error);
        setVotersListLoading(false);
      })
      .finally(() => {});
  };

  const onselectGender = (e) => {
    setGenderType(e.target.value);
  };

  const handleAgeTypeChange = (event) => {
    const selectedAgeType = event.target.value;
    setAgeType(selectedAgeType);
    if (selectedAgeType) {
      setFromAge("");
      setToAge("");
    }
    switch (selectedAgeType) {
      case "तरुण 18 - 35":
        setFromAge(18);
        setToAge(35);
        break;
      case "मध्यम वय 36 - 60":
        setFromAge(36);
        setToAge(60);
        break;
      case "वृद्ध 61 - 75":
        setFromAge(61);
        setToAge(75);
        break;
      case "वयोवृद्ध 75 - पुढे":
        setFromAge(76);
        setToAge("");
        break;
      default:
        setFromAge("");
        setToAge("");
    }
  };

  const onClearClick = () => {
    setFromPollingAddress("");
    setToPollingAddress("");
    setfromBoothNumber(0);
    settoBoothNumber(0);
    setFromAge("");
    setToAge("");
    setAgeType("");
    setVoterDetailsList([]);
    setShowVoterInfo(false);
    setScrollMore(true);
    setTotalCount("");
    setFromAgeError("");
    setToAgeError("");
    setGenderType("")
    getvoterinfobyage(candidateName, 1, false);
  };

  const nameTemplate = (rowData) => {
    return (
      <span>
        {rowData.firstName} {rowData.middleName} {rowData.lastName}
        <br />
        {rowData.voterId}
        <br />
        {rowData.gender}/{rowData.age}
      </span>
    );
  };

  return (
    <div className="app main-container-login voter-by-age-page">
      <div className="header">
        <div className="row mt-2">
          <div className="col-10">
            <label htmlFor="Constituency" className="form-label Polling text-light">
              वयानुसार मतदार{" "}
            </label>
          </div>
          <div className="col-2 text-end">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>

        <div className="row mt-3 text-14">
        <div className="col-6">
              <label className="polling-booth-label">पासून</label>
              <select
                name="fromPollingAddress"
                value={fromPollingAddress}
                className={`form-control form-select mb-1 text-14 polling-select ${
                  fromPollingAddress === "" || fromPollingAddress == null ? "option-as-placeholder" : ""
                }`}
                id="pollingAddress"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const boothNumber = selectedValue.split("-")[0];

                  onSelectFromAddressOfPolling(boothNumber, selectedValue);
                }}
              >
                <option className="option-as-placeholder" value="">
                  मतदान केंद्र
                </option>
                {pollingAddressList.map((item, index) => (
                  <option value={`${item.boothNumber}-${item.pollingAddress}`} key={index}>
                    {item.boothNumber}-{item.pollingAddress}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-6">
              <label className="polling-booth-label">पर्यंत</label>

              <select
                name="toPollingAddress"
                value={toPollingAddress}
                className={`form-control form-select mb-1 text-14 polling-select ${
                  toPollingAddress === "" || toPollingAddress == null ? "option-as-placeholder" : ""
                }`}
                id="pollingAddress"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const boothNumber = selectedValue.split("-")[0];

                  onSelectToAddressOfPolling(boothNumber, selectedValue);
                }}
              >
                <option className="option-as-placeholder" value="">
                  मतदान केंद्र
                </option>
                {pollingAddressList.map((item, index) => (
                  <option value={`${item.boothNumber}-${item.pollingAddress}`} key={index}>
                    {item.boothNumber}-{item.pollingAddress}
                  </option>
                ))}
              </select>
            </div>
          <div className="col-6">
            <label className="polling-booth-label">लिंग</label>
            <select
              name="Confirmation"
              id="confirmation"
              value={genderType}
              onChange={(e) => onselectGender(e)}
              className={`form-control form-select mb-1 text-14 polling-select ${
                genderType === "" || genderType == null ? "option-as-placeholder" : ""
              }`}
            >
              <option className="option-as-placeholder" value="">
                महिला / पुरुष / एकत्र
              </option>
              <option value="महिला">महिला</option>
              <option value="पुरुष">पुरुष</option>
              <option value="तृतिय पंथी">तृतिय पंथी</option>
            </select>
          </div>

          <div className="col-6">
            <label className="polling-booth-label">वय प्रकार</label>
            <select
              name="Confirmation"
              id="confirmation"
              value={ageType}
              className={`form-control form-select mb-1 text-14 polling-select ${ageType === "" || ageType == null ? "option-as-placeholder" : ""}`}
              onChange={handleAgeTypeChange}
            >
              <option className="option-as-placeholder" value="">
                वय प्रकार
              </option>
              <option value="तरुण 18 - 35">तरुण 18 - 35</option>
              <option value="मध्यम वय 36 - 60">मध्यम वय 36 - 60</option>
              <option value="वृद्ध 61 - 75">वृद्ध 61 - 75</option>
              <option value="वयोवृद्ध 75 - पुढे">वयोवृद्ध 75 - पुढे</option>
            </select>
          </div>

          <div className="col-6 ">
            <label className="polling-booth-label">
              {" "}
              वय पासून
              {/* <span className="text-danger"> * </span> */}
            </label>
            <input
              autofocus
              type="number"
              className="form-control form-select mb-1 text-14 polling-select"
              name="fromAge"
              value={fromAge}
              disabled={ageType !== ""} // Disable when ageType is selected
              onChange={(e) => onFromNameChange(e)}
              // disabled={isChecked ? true : false}
            />
          </div>
          <div className="col-6 ">
            <label className="polling-booth-label">
              वय पर्यंत
              {/* <span className="text-danger"> *</span> */}
            </label>
            <input
              autofocus
              type="number"
              disabled={ageType !== ""} // Disable when ageType is selected
              className="form-control form-select mb-1 text-14 polling-select"
              name="toAge"
              value={toAge}
              onChange={(e) => onToNameChange(e)}
              // disabled={isChecked ? true : false}
            />
          </div>

          <div className="col-6">
            <span className="text-12 text-danger mt-1">{fromAgeError}</span>
          </div>
          <div className="col-6">
            <span className="text-12 text-danger mt-1">{toAgeError}</span>
          </div>
          <div className="row mt-2">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <button className="btn show-btn w-auto text-15 me-2" onClick={() => onSearchClick(1, true)}>
                  शोधा
                  <img src={searchimg} alt="Search" style={{ marginLeft: "5px" }} />
                </button>
                <button className="btn show-btn w-auto text-15 me-2" onClick={onClearClick}>
                  रीसेट
                </button>
              </div>
              <div className="d-flex">
                <button className="btn w-auto text-15 text-white me-2">
                  <img src={print} alt="Print" style={{ marginLeft: "5px" }} /> प्रिंट
                </button>
                <button className="btn w-auto text-15 text-white">
                  <img src={share} alt="Share" style={{ marginLeft: "5px" }} /> शेअर
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container ">
          <BottomScrollListener onBottom={onShowmoreClicked}>
            {(scrollRef) => (
              <div className="mt-2" ref={scrollRef} style={{ height: "650px", overflowY: "auto", overflowX: "hidden" }}>
                {voterDetailsList.length != 0 ? (
                  <div className="row mx-auto">
                    <div className="col-2 total-details ">
                      <span>अनु क्र.</span>
                    </div>
                    <div className="col-2 total-details">
                      <span>भाग क्र.</span>
                    </div>
                    <div className="col-4  total-details ">
                      <span>नाव/व्होटर आयडी</span>
                    </div>
                    <div className="col-4  total-details ">
                      <span>मतदान केंद्राचे नाव</span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {votersListLoading ? (
                  <div className="d-flex justify-content-center loader">
                    <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                  </div>
                ) : (
                  voterDetailsList.map((item, number) => (
                    <div className="card mx-auto voter-list-card" key={number}>
                      <div className="row d-flex mx-auto">
                        <div className="col-2 ps-0 total-details">
                          <span className="voter-list-answer">{item.boothNumber}</span>
                        </div>
                        <div className="col-2 ps-0 total-details">
                          <span className="voter-list-answer">{item.slipNumber}</span>
                        </div>
                        <div className="col-4 ps-0 voter-list-answer total-details">{nameTemplate(item)}</div>
                        <div className="col-4 ps-0">
                          <span className="voter-list-answer">{item.addressOfPollingStation}</span>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </BottomScrollListener>
        </div>
      </div>
    </div>
  );
}
