import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";

export default function VoterByCast() {
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState("");
  const [pollingAddressList, setPollingAddressList] = useState([]);
  const [boothAddress, setBoothAddress] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [boothAddressList, setBoothAddressList] = useState([]);
  const [pollingAddress, setPollingAddress] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [caste, setCaste] = useState("");
  const [fromPollingAddress, setFromPollingAddress] = useState("");
  const [fromBoothNumber, setfromBoothNumber] = useState(0);
  const [pollingBoothError, setPollingBoothError] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getPollingAddresses(decoded.UserName);
      getBoothAddresses(decoded.UserName);
    }
  }, []);

  const onSelectFromAddressOfPolling = (boothNo, address) => {
    setfromBoothNumber(boothNo);
    setFromPollingAddress(address);
  };

  const checkValidation = () => {
    let returnValue = true;
    setPollingBoothError("");

    if (pollingAddress == null || pollingAddress == "") {
      setPollingBoothError("कृपया मतदान केंद्र निवडा");
      returnValue = false;
    }

    return returnValue;
  };

  const getBoothAddresses = (candidateName) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getboothaddress?candidateName=${candidateName}`,
    };

    axios(config)
      .then((response) => {
        let boothAddressList = response.data.response;
        setBoothAddressList(boothAddressList);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const getPollingAddresses = (candidateName) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetDropDownValue?candidateName=${candidateName}`,
    };

    axios(config)
      .then((response) => {
        let pollingAddressList = response.data.response;
        setPollingAddressList(pollingAddressList);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  const onSelectOfAddressOfPolling = (item) => {
    setPollingAddress(item);
  };
  const onSelectOfAddressBooth = (item) => {
    setBoothAddress(item);
  };
  const onselectCaste = (item) => {
    setCaste(item);
  };
  const onAllCheckboxSelect = (e) => {
    setIsChecked(e.target.checked);
    setPollingAddress("");
    setBoothAddress("");
  };
  const onSearchClick = (pageno, replace) => {
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);

    const data = {
      fromValue: 0,
      toValue: 0,
      searchPageNumber: pageno,
      searchResultCount: 10,
      caste: caste,
      fromBoothNumber: parseInt(fromBoothNumber),
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getvoterinfobycaste?candidateName=${candidateName}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
        }
        setShowVoterInfo(true);
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };
  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      onSearchClick(nextPageNumber, false);
    }
  };
  return (
    <div className="app main-container-login">
      <div className="header">
        <div className="row mt-2">
          <div className="col-10">
            <label htmlFor="Constituency" className="form-label Polling text-light">
              जात निहाय मतदार
            </label>
          </div>
          <div className="col-2 text-end">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>

        <div className="row mt-3 text-14">
          <label className="polling-booth-label"> मतदान केंद्र निवडा</label>
          <select
            name="fromPollingAddress"
            value={fromPollingAddress}
            className={`form-control form-select mb-1 text-14 polling-select ${
              fromPollingAddress === "" || fromPollingAddress == null ? "option-as-placeholder" : ""
            }`}
            id="pollingAddress"
            onChange={(e) => {
              const selectedValue = e.target.value;
              const boothNumber = selectedValue.split("-")[0];

              onSelectFromAddressOfPolling(boothNumber, selectedValue);
            }}
          >
            <option className="option-as-placeholder" value="">
              मतदान केंद्र
            </option>
            {pollingAddressList.map((item, index) => (
              <option value={`${item.boothNumber}-${item.pollingAddress}`} key={index}>
                {item.boothNumber}-{item.pollingAddress}
              </option>
            ))}
          </select>
          </div>
          <div className="row mt-3 text-14">
          <label className="polling-booth-label"> Select caste</label>
            <select
              name="Caste"
              value={caste || ""}
              className={`form-control form-select input-box mb-1 text-14 polling-select ${
                caste == "" || caste == null ? "option-as-placeholder" : ""
              }`}
              id="Caste"
              onChange={(e) => onselectCaste(e.target.value)}
            >
              <option className="option-as-placeholder" value="">
              जात निवडा
              </option>
              <option value="हिंदू">हिंदू</option>
              <option value="शीख">शीख</option>
              <option value="जैन">जैन</option>
              <option value="बौद्ध">बौद्ध</option>
              <option value="मुस्लिम">मुस्लिम</option>
              <option value="ख्रिश्चन">ख्रिश्चन</option>
            </select>
          </div>
          <div className="row  d-flex align-items-center">
            <div className="col-7 mt-2 ">
              <span className="form-label fw-bold voter-list-label text-light">Total Count : {totalCount} </span>
            </div>
            <div className="col-5 mt-2 text-end ">
              <button className="btn  show-btn w-auto text-12" onClick={() => onSearchClick(1, true)} disabled ={fromPollingAddress === null || fromPollingAddress === ""}>
                {" "}
                Search
              </button>
            </div>
        </div>
        {showVoterInfo ? (
          <BottomScrollListener onBottom={onShowmoreClicked}>
            {(scrollRef) => (
              <div className="mt-2" ref={scrollRef} style={{ height: "450px", overflowY: "auto", overflowX: "hidden" }}>
                {voterDetailsList.map((item, number) => (
                  <div
                    className={
                      voterDetailsList.length == number + 1 && votersListLoading == false
                        ? " card mb-2 voter-list-card last-card-bottom"
                        : "card mb-2 voter-list-card"
                    }
                    key={number}
                  >
                    <div className="row  mx-auto ">
                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label"> {item.slipNumber}</span>
                      </div>
                      <div className="col-5 p-0 mb-1">
                        <span className="voter-list-answer">{item.voterId}</span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">नाव</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">
                          {item.lastName} {item.firstName} {item.middleName}
                        </span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">फोन नंबर</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">{item.mobileNo}</span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">पत्ता</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">
                          {item.wordName}, {item.district}
                        </span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">घर क्रमांक</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">{item.houseNumber}</span>
                      </div>
                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">वय</span>
                      </div>
                      <div className="col-3 ps-0 mb-1 ">
                        <span className="voter-list-answer">{item.age}</span>
                      </div>
                      <div className="col-2 mb-1">
                        <span className="fw-bold voter-list-label"> लिंग</span>
                      </div>
                      <div className="col-3 ps-0 mb-1">
                        <span className="voter-list-answer">{item.gender}</span>
                      </div>
                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">जन्मदिनांक</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">
                          {item.birthDate != "" && item.birthDate != null ? moment(item.birthDate).format("YYYY-MM-DD") : ""}
                        </span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">कुटुंब सदस्य</span>
                      </div>
                      <div className="col-3 ps-0 pe-0 mb-1 ">
                        <span className="voter-list-answer">{item.familyMemberCount}</span>
                      </div>
                      <div className="col-2 mb-1">
                        <span className="fw-bold voter-list-label">जात</span>
                      </div>
                      <div className="col-3 ps-0 mb-1">
                        <span className="voter-list-answer">{item.caste}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </BottomScrollListener>
        ) : null}

        {votersListLoading ? (
          <div className="d-flex justify-content-center">
            <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
