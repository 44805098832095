import React from 'react'
import Banner from '../Assets/Images/banner-img.svg';
import Back from '../Assets/Images/backbtn.svg';
import Home from '../Assets/Images/home_icon.svg'
import { NavLink, useNavigate } from 'react-router-dom';
import photo from '../Assets/Images/JayashreetaiMadanbhauPatil 1.svg'
import headerIcon from '../Assets/Images/Icon.svg'

export default function Header() {
  const navigate = useNavigate();

  const onBackClick=()=>{
    navigate('/dashboard');
  }
  const handleDashboardClick = () => {
      navigate('/dashboard');
  };
  return (

    <div className='header'>
      <div className='header-bg'>
        <div className='row'>
          <div className='col-2'>    
             <img src={photo} alt="Scanner image" />
             </div>
          <div className='col-8 header-middle-title'> 
          <div className='smaller-text'>२८२ सांगली विधानसभा मतदार संघातील काँग्रेस पक्षाचे अधिकृत उमेदवार </div>
          <div className='large-text'>श्रीमती जयश्रीताई मदनभाऊ पाटील यांना प्रचंड <br/>बहुमताने निवडून द्या !</div>
          </div> 
          <div className='col-2 text-end' style={{padding:"0px"}}> 
            <img src={headerIcon} alt="Scanner image" style={{marginRight:"13px"}}/> </div>
        </div>

 
      </div>

    </div>

  )
}