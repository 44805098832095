import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import './App.css';
import CandidateInfo from "./Pages/CandidateInfo";
import SearchResult from "./Pages/SearchResult";
import ThankYou from "./Pages/ThankYou";
import VoterForm from "./Pages/VoterForm";
import VoterList from "./Pages/VoterList";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import Bio from "./Pages/Bio";
import CandidateDashbord from "./Pages/CandidateDashbord";
import PollingBooth from "./Pages/PollingBooth";
import DisplayVoterList from "./Pages/DisplayVoterList";
import VotersByAge from "./Pages/VotersByAge";
import AreaWiseList from "./Pages/AreaWiseList";
import VoterByCast from "./Pages/VoterByCast";
import VisitedVoters from "./Pages/VisitedVoters";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import FieldWorkerList from "./Pages/FieldWorkerList";
import MarriedFemaleVoters from "./Pages/MarriedFemaleVoters";
import VotersBySurname from "./Pages/VotersBySurname";
import DeadVoters from "./Pages/DeadVoters";
import AddressChangedVoters from "./Pages/AddressChangedVoters";
import PhoneNumberList from "./Pages/PhoneNumberList";
import FamilyAddedList from "./Pages/FamilyAddedList";
import ConfirmedVotersList from "./Pages/ConfirmedVotersList";
import PollingAreaPLacesList from "./Pages/PollingAreaPLacesList";
import BirthDateList from "./Pages/BirthDateList";
import BirthdayList from "./Pages/BirthdayList";
import "../src/Assets/Css/style.css";
import PollingAgent from "./Pages/PollingAgent";
import CandidateConfig from "./Pages/CandidateConfig";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<VoterForm />} />
        <Route path="/candidate" element={<VoterForm />} />
        <Route path="/searchresult" element={<SearchResult />} />
        <Route path="/voterList" element={<VoterList />} />
        <Route path="/thankupage" element={<ThankYou />} />
        <Route path="/candidateinfo" element={<CandidateInfo />} />
        <Route path="/login" element={<Login />} />
        <Route path="/candidatedashboard" element={<CandidateDashbord />} />
        <Route path="/pollingbooth" element={<PollingBooth />} />
        <Route path="/bio" element={<Bio />} />
        <Route path="/displayvoterlist" element={<DisplayVoterList />} />
        <Route path="/votersbyage" element={<VotersByAge />} />
        <Route path="/areawiselist" element={<AreaWiseList />} />
        <Route path="/voterbycast" element={<VoterByCast />} />
        <Route path="/visitedvoters" element={<VisitedVoters />} />
        <Route path="/fieldworkerlist" element={<FieldWorkerList />} />
        <Route path="/marriedfemalelist" element={<MarriedFemaleVoters />} />
        <Route path="/surnamelist" element={<VotersBySurname />} />
        <Route path="/deadvoters" element={<DeadVoters />} />
        <Route path="/changedaddressvoters" element={<AddressChangedVoters />} />
        <Route path="/phonenumberlist" element={<PhoneNumberList />} />
        <Route path="/votersfamilylist" element={<FamilyAddedList />} />
        <Route path="/confirmedvoterslist" element={<ConfirmedVotersList />} />
        <Route path="/pollingarea" element={<PollingAreaPLacesList />} />
        <Route path="/birthdatelist" element={<BirthDateList />} />
        <Route path="/birthdaylist" element={<BirthdayList />} />
        <Route path="/pollingAgent" element={<PollingAgent />} />
        <Route path="/CandidateConfig" element={<CandidateConfig />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
