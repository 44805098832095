import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Back from '../Assets/Images/backbtn.svg';
import Home from '../Assets/Images/home_icon.svg'

export default function ThankYou() {
    const navigate = useNavigate();

    const handlemorecandidateclick = () => {
        navigate('/candidateinfo');
    };

    const onBackClick = () => {
        navigate('/voterlist');
    }
    const handleDashboardClick = () => {
        navigate('/dashboard');
    };
    return (
        <div className="app">
            <Header />
            <div className='row'>
                <div className='col-6'>
                    <img src={Back} alt="Scanner image" className='back-btn-img' onClick={onBackClick} />
                </div>

                <div className='col-6 text-end'>
                    <img src={Home} alt="Scanner image" className='home-btn-img' onClick={handleDashboardClick} />
                </div>
            </div>
            <div className="container  thanku-form justify-content-center">
                <div className='thanku-form'>
                    <div className='Thank-you-div-text'>Thank You</div>
                    <div className='Thank-you-div-text'>for your connecting with us</div>
                    <div className='Thank-you-div-text' style={{ marginTop: "10px" }}>Our team Co-ordinate</div>
                    <div className='Thank-you-div-text'>with you in next 24hrs</div>

                    <div className='mt-5'>
                        <button type="button" class="btn btn-primary btn-back">You want to Search more names</button>

                    </div>
                </div>

            </div>
            <div className='footer'>
                <div className='mb-3 text-center'>
                    <button type="button" class="btn btn-primary btn-back" onClick={handlemorecandidateclick}>Want to know more about candidate</button>

                </div>

            </div></div>
    )
}
