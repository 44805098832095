import React from 'react'
import { useNavigate, NavLink } from 'react-router-dom';
import DashboardBanner from '../Assets/Images/dashboard-banner.svg';
import Home from '../Assets/Images/home_icon.svg'

export default function Bio() {
  const navigate = useNavigate();


  const handleDashboardClick = () => {
    navigate('/dashboard');
};
  return (
    <>
      <div className='header'>
        <img src={DashboardBanner} alt="Scanner image" className='dashboardimg' />
        <img src={Home} alt="Scanner image" className='home-btn-img' style={{position:"absolute",top:"10px",left:"20px"}} onClick={handleDashboardClick}/>
      </div>
      <div className='text-center bio-main-div'>
        <div className='header-bio'>श्रीमती जयश्रीताई मदनभाऊ पाटील </div>
        <div className='header-bio-secondTitle'>यांच्या विषयी संक्षिप्त माहिती</div>
        <div className=''>
          जयश्री पाटील या दिवंगत नेते आणि माजी मंत्री मदनभाऊ पाटील यांच्या पत्नी, तर महाराष्ट्राचे माजी मुख्यमंत्री वसंतदादा पाटील (Vasant Dada Patil) यांच्या नातसून आहेत.
          महाविकास आघाडीकडून भाजपचा धुव्वा
          सांगली जिल्हा बँकेसाठी 21 नोव्हेंबरला मतदान होऊन 23 नोव्हेंबरला मतमोजणी झाली होती. राष्ट्रवादी, कॉंग्रेस आणि शिवसेना यांच्या महाआघाडीच्या सहकार पॅनेलने 17 तर भाजप प्रणित शेतकरी विकास पॅनेलने 4 जागांवर विजय मिळवला होता.
          महाआघाडीची सत्ता आल्यानंतर अध्यक्ष आणि उपाध्यक्षपदाबाबत उत्सुकता होती. अध्यक्षपदावर आमदार मानसिंगराव नाईक यांचा प्रबळ दावा होता. त्यामुळे त्यांची निवड ही निश्‍चितच होती. तर उपाध्यक्षपदी कॉंग्रेसच्या श्रीमती जयश्री पाटील यांचेच नाव चर्चेत होते.
          पतंगराव कदमांनंतर जयश्री पाटलांचं मोठं काम
          काँग्रेस नेते डॉ. पतंगराव कदम, मदन पाटील यांच्या पश्चात शहरात काँग्रेसला बळ देण्याचे काम जयश्री पाटील यांनी केले. लोकसभा, विधानसभा, महापालिका निवडणुकीसह पक्षाच्या विविध पातळीवर कार्यक्रम, आंदोलनात त्या सहभागी असत. गेल्या महापालिका निवडणुकीतही काँग्रेस-राष्ट्रवादी आघाडी स्थापनेपासून ते निवडणूक प्रचारातही त्यांनी आघाडी सांभाळली होती. परंतु, त्या तुलनेत काँग्रेसमधून त्यांना व कार्यकर्त्यांना न्याय दिला जात नाही, अशी खदखद त्यांच्या मनात होती.
        </div>
        <div className='row '>
        <div className='col-6 card last-card-div' style={{marginLeft:"-4px"}}>जनमानसातील <br/>प्रतिक्रिया </div>
        <div className='col-6 card last-card-div' style={{marginLeft:"4px"}}>मतदार संघातील योगदान</div>
        </div>
     
      </div>

    </>
  )
}