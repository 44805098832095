import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { ThreeDots } from "react-loader-spinner";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

export default function WordName(props) {
  const navigate = useNavigate();
  const toast = useRef("");
  const [toPollingAddress, setToPollingAddress] = useState("");
  const [fromPollingAddress, setFromPollingAddress] = useState("");
  const [toBoothNumber, settoBoothNumber] = useState(0);
  const [wordNameList, setWordNameList] = useState([]);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [candidateName, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [pollingAddressList, setPollingAddressList] = useState([]);
  const [fromBoothNumber, setfromBoothNumber] = useState(0);
  const [selectedBooths, setSelectedBooths] = useState([]); // state for selected booths
  const [selectedWordNames, setSelectedWordNames] = useState([]); // state for selected word names

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getFieldWorkerList(1, true, candidateName);
      getPollingBoothList(candidateName);
    }
  }, [toBoothNumber]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    let candidateName = decoded.UserName;
    GetPollingDetailsByWordName(candidateName);
  }, [toBoothNumber]);

  const GetPollingDetailsByWordName = (candidateNm) => {
    setVotersListLoading(true);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Worker/GetPollingDetailsByWordName?candidateName=${candidateNm}&FromBoothNumber=${fromBoothNumber}&ToBoothNumber=${toBoothNumber}`,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          // Group the response data
          const groupedData = response.data.response.reduce((acc, item) => {
            const key = `${item.boothNumber}-${item.pollingAddress}`;
            if (!acc[key]) {
              acc[key] = {
                boothNumber: item.boothNumber,
                pollingAddress: item.pollingAddress,
                wordName: [],
              };
            }
            acc[key].wordName.push(item.wordName);
            return acc;
          }, {});

          // Convert the grouped data back into an array and update the state
          setWordNameList(Object.values(groupedData));
        }
      })
      .catch((error) => {
        console.log(error);
        setVotersListLoading(false);
      })
      .finally(() => {});
  };

  const onSelectFromAddressOfPolling = (boothNo, address) => {
    setfromBoothNumber(boothNo);
    setFromPollingAddress(address);
  };
  const onSelectToAddressOfPolling = (boothNo, address) => {
    settoBoothNumber(boothNo);
    setToPollingAddress(address);
    GetPollingDetailsByWordName(candidateName, fromBoothNumber, toBoothNumber);
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getFieldWorkerList(1, true, candidateName);
    }
  }, []);

  const getFieldWorkerList = (pageno, replace, candidateNm) => {
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);

    const data = {
      boothAddress: "",
      pollingAddress: "",
      fromValue: 0,
      toValue: 0,
      isGetAllValue: false,
      searchPageNumber: pageno,
      searchResultCount: 10,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getworkerdetails?candidateName=${candidateNm}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
        }
        setShowVoterInfo(true);
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };

  const getPollingBoothList = (candidateNm) => {
    setVotersListLoading(true);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetDropDownValue?candidateName=${candidateNm}`,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          setPollingAddressList(response.data.response);
        }
      })
      .catch((error) => {
        console.log(error);
        setVotersListLoading(false);
      })
      .finally(() => {});
  };

  const handleBoothCheckboxChange = (boothNumber) => {
    setSelectedBooths((prevSelectedBooths) => {
      if (prevSelectedBooths.includes(boothNumber)) {
        // Remove if already selected
        return prevSelectedBooths.filter((item) => item !== boothNumber);
      } else {
        // Add if not selected
        return [...prevSelectedBooths, boothNumber];
      }
    });
  };

  const handleWordNameCheckboxChange = (boothNumber, wordName) => {
    const key = `${boothNumber}-${wordName}`;
    setSelectedWordNames((prevSelectedWordNames) => {
      if (prevSelectedWordNames.includes(key)) {
        // Remove if already selected
        return prevSelectedWordNames.filter((item) => item !== key);
      } else {
        // Add if not selected
        return [...prevSelectedWordNames, key];
      }
    });
  };

  const handleShowWordName = () => {
    props.handleCallBack(selectedBooths, selectedWordNames);
  };

  return (
    <div className="app main-container-login">
      <Toast ref={toast} />
      <div className="header">
        <div className="row mt-1">
          <div className="col-6">
            <label className="polling-booth-label">पासून</label>
            <select
              name="fromPollingAddress"
              value={fromPollingAddress}
              className={`form-control form-select mb-1 text-14 polling-select ${
                fromPollingAddress === "" || fromPollingAddress == null ? "option-as-placeholder" : ""
              }`}
              id="pollingAddress"
              onChange={(e) => {
                const selectedValue = e.target.value;
                const boothNumber = selectedValue.split("-")[0];

                onSelectFromAddressOfPolling(boothNumber, selectedValue);
              }}
            >
              <option className="option-as-placeholder" value="">
                मतदान केंद्र
              </option>
              {pollingAddressList.map((item, index) => (
                <option value={`${item.boothNumber}-${item.pollingAddress}`} key={index}>
                  {item.boothNumber}-{item.pollingAddress}
                </option>
              ))}
            </select>
          </div>

          <div className="col-6">
            <label className="polling-booth-label">पर्यंत</label>

            <select
              name="toPollingAddress"
              value={toPollingAddress}
              className={`form-control form-select mb-1 text-14 polling-select ${
                toPollingAddress === "" || toPollingAddress == null ? "option-as-placeholder" : ""
              }`}
              id="pollingAddress"
              onChange={(e) => {
                const selectedValue = e.target.value;
                const boothNumber = selectedValue.split("-")[0];

                onSelectToAddressOfPolling(boothNumber, selectedValue);
              }}
            >
              <option className="option-as-placeholder" value="">
                मतदान केंद्र
              </option>
              {pollingAddressList.map((item, index) => (
                <option value={`${item.boothNumber}-${item.pollingAddress}`} key={index}>
                  {item.boothNumber}-{item.pollingAddress}
                </option>
              ))}
            </select>
          </div>
        </div>

        {Object.values(wordNameList).map((item, index) => (
          <div key={index} className="card worker-info-card text-light text-14 mt-2">
            <div className="row pb-1 align-items-center">
              <div className="col-1">
                <input
                  type="checkbox"
                  checked={selectedBooths.includes(item.boothNumber)}
                  onChange={() => handleBoothCheckboxChange(item.boothNumber)}
                />
              </div>
              <div className="col-11">
                <span>{item.boothNumber}</span>
              </div>
            </div>
            <div className="row pb-1 ">
              <div className="col-1"></div>
              <div className="col-11">
                <span>{item.pollingAddress}</span>
              </div>
            </div>
            <div className="row pb-1 ">
              <div className="col-6 mb-2">
                <span>
                  <b>क्षेत्र नियुक्त करा</b>
                </span>
              </div>
            </div>
            {item.wordName.map((wordName, wordIndex) => (
              <div key={wordIndex} className="row">
                <div className="col-1">
                  <input
                    type="checkbox"
                    checked={selectedWordNames.includes(`${item.boothNumber}-${wordName}`)}
                    onChange={() => handleWordNameCheckboxChange(item.boothNumber, wordName)}
                  />
                </div>
                <div className="col-11">{wordName}</div>
              </div>
            ))}
          </div>
        ))}
        <div className="row">
          <div className="col-12 text-end selectFieldArea">
            <button
              className="btn show-btn w-auto text-15"
              onClick={(e) => {
                e.preventDefault();
                handleShowWordName();
              }}
            >
              क्षेत्र जोडा
            </button>
          </div>
        </div>
        {votersListLoading ? (
          <div className="d-flex justify-content-center">
            <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
