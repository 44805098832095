import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import home from "../Assets/Images/home_icon.svg";
import searchimg from "../Assets/Images/search.svg";
import share from "../Assets/Images/sharevoter.svg";
import print from "../Assets/Images/print.svg";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { ThreeDots } from "react-loader-spinner";
import confirmationImg from "../Assets/Images/handshake.svg";
import confirmedImg from "../Assets/Images/Vector.svg";
import { BottomScrollListener } from "react-bottom-scroll-listener";

export default function PollingAgent() {
  const navigate = useNavigate();
  const [pollingAddressList, setPollingAddressList] = useState([]);
  const [pollingAddress, setPollingAddress] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [fullName, setFullName] = useState("");
  const [serialNumber, setSerialNumber] = useState(0);
  const [pollingListLoading, setPollingListLoading] = useState(false);
  const [candidateName, setCandidateName] = useState("");
  const [pollingAgentList, setPollingAgentList] = useState([]);
  const [pollingBoothError, setPollingBoothError] = useState("");
  const [votingDoneLoading, setVotingDoneLoading] = useState(false);
  const [voterUniqueId, setVoterUniqueId] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [totalCount, setTotalCount] = useState("");
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [address, setAddress] = useState("");
  const [pollingStats, setPollingStats] = useState({totalConfirmedVoter: 0,
                                                    totalDoneConfirmVoter: 0,
                                                    totalDoneVoter: 0,
                                                    totalVoter: 0,
                                                    });
const [toBoothNumber, settoBoothNumber] = useState(0);


  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token != "" && token != null) {
      const decoded = jwtDecode(token);
      getPollingAddresses(decoded.UserName);
      getPollingAgentList(pageNumber, false, decoded.UserName);
      setCandidateName(decoded.UserName);
    } else {
      navigate("/login");
    }
  }, []);

  const getPollingAgentList = (pageno, replace, candidate) => {
    if (isApiCallInProgress) return;
    setPollingListLoading(true);
    let VoterName = fullName && fullName.trim() ? fullName.trim().split(" ") : [""];
    const data = {
      firstName: VoterName[0] == null || VoterName[0] == undefined ? "" : VoterName[0],
      middleName: VoterName[1] == null || VoterName[1] == undefined ? "" : VoterName[1],
      lastName: VoterName[2] == null || VoterName[2] == undefined ? "" : VoterName[2],
      FromBoothNumber: parseInt(toBoothNumber),     
     slipNumber: serialNumber == "" || serialNumber == null ? 0 : parseInt(serialNumber),
      searchPageNumber: pageno,
      searchResultCount: 25,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/pollingagent?candidateName=${candidate}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.response.length == 0) {
          setScrollMore(false);
        } else {
          setScrollMore(true);
        }
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...pollingAgentList, ...response.data.response];
          setPollingAgentList(searchedList);
          setPollingStats(prevStats => ({
            ...prevStats,  
            totalDoneConfirmVoter: response.data.response[0].totalDoneConfirmVoter,
            totalDoneVoter: response.data.response[0].totalDoneVoter,
            totalConfirmedVoter: response.data.response[0].totalConfirmedVoter,
            totalVoter:response.data.response[0].totalVoter
          }));
          
        }
        setPollingListLoading(false);
      })
      .catch((error) => {
        setPollingListLoading(false);
      })
      .finally(() => {
        setPollingListLoading(false);
      });
  };
  const ConfirmationTemplate = (rowData) => {
    return (
      <span>
        <img 
          src={rowData.votingConfirmation === "Yes" ? confirmedImg : ""} 
          className="" 
          alt={rowData.votingConfirmation === "Yes" ? "Confirmed" : ""} 
        />
      </span>
    );
  };
  
  const onShowmoreClicked = () => {
    if (scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      getPollingAgentList(nextPageNumber, false, candidateName);
    }
  };

  const onSelectOfAddressOfPolling = (item,value) => {
    settoBoothNumber(item);
    setAddress(item);
    setPollingAddress(value);
  };
  const getPollingAddresses = (candidateName) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetDropDownValue?candidateName=${candidateName}`,
    };

    axios(config)
      .then((response) => {
        let pollingAddressList = response.data.response;
        setPollingAddressList(pollingAddressList);
      })
      .catch((error) => {
      })
      .finally(() => {});
  };
  const onSerialNumberChange = (e) => {
    setSerialNumber(e.target.value);
  };
  const onNameChange = (value) => {
    let replaceValue;
    if (value != fullName || fullName == "") {
      replaceValue = true;
    } else {
      replaceValue = false;
    }
    setFullName(value);
    setPageNumber(1);
  };
  const onEnterClick = (e) => {
    if (e.keyCode == 13) {
      getPollingAgentList(pageNumber, true, candidateName);
    }
  };
  const checkValidation = () => {
    let returnValue = true;
    setPollingBoothError("");

    if (pollingAddress == null || pollingAddress == "") {
      setPollingBoothError("कृपया मतदान केंद्र निवडा");
      returnValue = false;
    }

    return returnValue;
  };

  const onSearchClick = () => {
    if (checkValidation()) {
      getPollingAgentList(1, 1, candidateName);
    }
  };
  const nameTemplate = (rowData) => {
    return (
      <span>
        {rowData.firstName} {rowData.middleName} {rowData.lastName}{" "}
      </span>
    );
  };
  const onVotingDoneOrNotClick = (id) => 
   {
      setVotingDoneLoading(true);
      setVoterUniqueId(id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/updateVoterDone?Id=${id}`,
      };

      axios(config)
        .then((response) => {
          setPollingAgentList((prevResponses) =>
            prevResponses.map((response) => (response.id === id ? { ...response, isVotingDone: !response.isVotingDone } : response))
          );
          setVotingDoneLoading(false);
        })
        .catch((error) => {
          setVotingDoneLoading(false);
        });
        updateVotingData();
    };

  const updateVotingData=()=>
    {
      
      let VoterName = fullName && fullName.trim() ? fullName.trim().split(" ") : [""];
      const data = {
        firstName: VoterName[0] == null || VoterName[0] == undefined ? "" : VoterName[0],
        middleName: VoterName[1] == null || VoterName[1] == undefined ? "" : VoterName[1],
        lastName: VoterName[2] == null || VoterName[2] == undefined ? "" : VoterName[2],
        PollingAddress: address,
        slipNumber: serialNumber == "" || serialNumber == null ? 0 : parseInt(serialNumber),
        searchResultCount: 25,
        searchPageNumber:1
      };
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/pollingagent?candidateName=${candidateName}`,
        data: data,
      };
  
      axios(config)
        .then((response) => {
          
          if (response.data.response.length > 0)
          {
            setPollingStats(prevStats => 
            ({
              ...prevStats,  
              totalDoneConfirmVoter: response.data.response[0].totalDoneConfirmVoter,
              totalDoneVoter: response.data.response[0].totalDoneVoter,
              totalConfirmedVoter: response.data.response[0].totalConfirmedVoter,
              totalVoter:response.data.response[0].totalVoter
            }));
          }
        })
    }

  const votingTemplate = (rowData) => {
    return (
      <div className="col-6 ps-0 pe-0  ">
        {votingDoneLoading && voterUniqueId == rowData.id ? (
          <div className="d-flex justify-content-center ">
            <ThreeDots color="#fff" height={30} width={30} />
          </div>
        ) : (
          <>
            <input
              type="checkbox"
              id={`votingConfirmationSwitch_${rowData.id}`}
              className="checkbox"
              onChange={() => onVotingDoneOrNotClick(rowData.id)}
              checked={rowData.isVotingDone}
            />
            <label
              htmlFor={`votingConfirmationSwitch_${rowData.id}`}
              className={`fw-bold voting toggle ${
                rowData.isVotingDone ? "justify-content-start checked-toggle-span" : "justify-content-end unchecked-toggle-span"
              }`}
            >
              <span className="text-12" style={{color:"gray"}}>{rowData.isVotingDone == true ? "आहे" : "नाही"}</span>
            </label>
          </>
        )}
      </div>
    );
  };
  return (
    <div className="app main-container-login">
      <div className="header">
            <div className="row mt-2">
          <div className="col-10">
            <label htmlFor="Constituency" className="form-label Polling text-light">
            पोलिंग एजन्ट
            </label>
          </div>
          <div className="col-2 text-end">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>
        <div className="row mt-3 text-14">
          <div className="col-12 text-14">
            <select
              name="pollingAddress"
              value={pollingAddress}
              className={`form-control form-select mb-1 text-14 polling-select ${
                pollingAddress == "" || pollingAddress == null ? "option-as-placeholder" : ""
              }`}
              id="pollingAddress"
              onChange={(e) => {
                const selectedValue = e.target.value;
                const boothNumber = selectedValue.split("-")[0];

                onSelectOfAddressOfPolling(boothNumber, selectedValue);
              }}
            >
              <option className="option-as-placeholder" value="">
              मतदान केंद्र निवडा
              </option>
              {pollingAddressList.map((item, index) => (
                <option value={`${item.boothNumber}-${item.pollingAddress}`} key={index}>
                {item.boothNumber}-{item.pollingAddress}
              </option>
              ))}
            </select>
            <span className="text-danger">{pollingBoothError}</span>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-4">
            <input
              type="text"
              onChange={onSerialNumberChange}
              value={serialNumber == 0 ? "" : serialNumber}
              className="form-control input-color"
              id="text"
              placeholder="अनु क्र. "
              onKeyDown={onEnterClick}
            ></input>
          </div>
          <div className="col-8">
            <input
              type="text"
              onChange={(e) => onNameChange(e.target.value)}
              value={fullName}
              className="form-control input-color"
              id="text"
              placeholder="नाव"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-4 mt-1">
            <button className="btn show-btn w-auto text-15" onClick={onSearchClick}>
              {" "}
              शोधा
              <img src={searchimg} alt="Search" style={{ marginLeft: "5px" }} />
            </button>
          </div>
          <div className="col-8 text-end">
            <button className="btn w-auto text-15 text-white">
              <img src={print} alt="Search" style={{ marginLeft: "5px" }} /> प्रिंट
            </button>
            <button className="btn w-auto text-15 text-white">
              <img src={share} alt="Search" style={{ marginLeft: "5px" }} /> शेअर
            </button>
          </div>
        </div>
        <div className="row mx-auto mt-2 ">
          <div className="col-3 total-details ">
            <span>झालेले मतदान</span>
            <span>{pollingStats.totalDoneVoter ? pollingStats.totalDoneVoter : 0}</span>
          </div>
          <div className="col-3 total-details">
            <span>कन्फर्म</span>
            <span>{pollingStats.totalDoneConfirmVoter ? pollingStats.totalDoneConfirmVoter : 0}/{pollingStats.totalConfirmedVoter ? pollingStats.totalConfirmedVoter : 0}</span>
          </div>
          <div className="col-3  total-details ">
            <span>न झालेले मतदान</span>
            <span>{pollingStats.totalVoter ? pollingStats.totalVoter - pollingStats.totalDoneVoter : 0}</span>
          </div>
          <div className="col-3  total-details ">
            <span>एकूण मतदान</span>
            <span>{pollingStats.totalVoter ? pollingStats.totalVoter : 0}</span>
          </div>
        </div>
      </div>
      <div className="container ">
        <BottomScrollListener onBottom={onShowmoreClicked}>
          {(scrollRef) => (
            <div className="mt-2" ref={scrollRef} style={{ height: "650px", overflowY: "auto", overflowX: "hidden" }}>
              {pollingAgentList.length != 0 ? (
                <div className="row mx-auto">
                  <div className="col-2 total-details ">
                    <span className="col-2">अनु क्र.</span>
                  </div>
                  <div className="col-6 total-details">
                    <span span className="col-2">नाव</span>
                  </div>
                  <div className="col-1  total-details ">
                  </div>
                  <div className="col-3  total-details ">
                  <span span className="col-2">कन्फर्म</span>

                  </div>
                </div>
              ) : (
                <></>
              )}
      {pollingListLoading ? (
        <div className="d-flex justify-content-center">
          <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
        </div>
        ) :  (
                pollingAgentList.map((item, number) => (
                  <div className="card mx-auto voter-list-card" key={number}>
                    <div className="row d-flex mx-auto">
                      <div className="col-2 ps-0">
                        <span className="voter-list-answer">{item.slipNumber}</span>
                      </div>
                      <div className="col-6 ps-0">
                        <span className="voter-list-answer">
                          {item.lastName} {item.firstName} {item.middleName}
                        </span>
                      </div>
                      <div className="col-1 ps-0">
                        {ConfirmationTemplate(item)}
                      </div>
                      <div className="col-2 ps-0">
                        {votingTemplate(item)}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </BottomScrollListener>
      </div>
    </div>
  );
}
