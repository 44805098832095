import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import _ from "lodash";
import { Toast } from "primereact/toast";
import moment from "moment";

export default function DisplayVoterList() {
  const [searchSuggestionList, setSearchSuggestionList] = useState([]);
  const [fullName, setFullName] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [scrollMore, setScrollMore] = useState(true);
  const [suggestionListLoading, setSuggestionListLoading] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [editVoterId, setEditVoterId] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("selectedLanguage") || "Marathi");
  const [showFamilyCards, setShowFamilyCards] = useState(false);
  const navigate = useNavigate();
  const toast = useRef("");
  const [workerId, setWorkerId] = useState(0);
  const [candidateId, setCandidateId] = useState(0);
  const [familyList, setFamilyList] = useState([]);
  const [showFamilyListLoading, setShowFamilyListLoading] = useState(false);
  const [showSeperateFamilyDetailsCard, setShowSeperateFamilyDetailsCard] = useState(false);
  const [showFamilyDetailsId, setShowFamilyDetailsId] = useState("");
  const [addingFamilyLoading, setAddingFamilyLoading] = useState(false);
  const [primaryFamilyMemberId, setPrimaryFamilyMemberId] = useState(0);

  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };
  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      if (decoded.isExistsInCandidate == "True") {
        let candidateName = decoded.UserName;
        setCandidateName(candidateName);
        setCandidateId(decoded.Id);
        onNameSearchSuggestion("", pageNumber, false, "", candidateName, 0);
      } else {
        let workerId = decoded.Id;
        setWorkerId(workerId);
        onNameSearchSuggestion("", pageNumber, false, "", "", decoded.Id);
      }
    }
  }, []);
  const onChangeFirstName = (value) => {
    let replaceValue;
    if (value != fullName || fullName == "") {
      replaceValue = true;
    } else {
      replaceValue = false;
    }
    setSearchSuggestionList([]);
    setShowFamilyCards(false);
    setShowEditMode(false);

    setFullName(value);
    setPageNumber(1);
    if (value) {
      debouncedFetchResults(value, 1, replaceValue);
    } else {
      debouncedFetchResults(value, 1, replaceValue);
    }
    return () => {
      debouncedFetchResults.cancel();
    };
  };
  const debouncedFetchResults = useCallback(
    _.debounce((searchValue, page, replaceValue) => {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      const candidateName = decoded.UserName;
      if (decoded.isExistsInCandidate == "True") {
        let candidateName = decoded.UserName;
        setCandidateName(candidateName);
        onNameSearchSuggestion(searchValue, page, replaceValue, "", candidateName, 0);
      } else {
        let workerId = decoded.Id;
        setWorkerId(workerId);
        onNameSearchSuggestion(searchValue, page, replaceValue, "", "", decoded.Id);
      }
    }, 500),
    []
  );
  const onNameSearchSuggestion = (searchValue, page, replace, voterIdNumber, candidateName, worker) => {
    let VoterName = searchValue.split(" ");
    setSuggestionListLoading(true);
    const data = {
      firstName: VoterName[0] == null || VoterName[0] == undefined ? "" : VoterName[0],
      middleName: VoterName[1] == null || VoterName[1] == undefined ? "" : VoterName[1],
      lastName: VoterName[2] == null || VoterName[2] == undefined ? "" : VoterName[2],
      workerId: worker,
      searchResultCount: 10,
      searchPageNumber: page,
      voterId: voterIdNumber == null || undefined ? "" : voterIdNumber,
    };
    const selectLanguage = localStorage.getItem("selectedLanguage");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/searchsuggestions?candidateName=${
        candidateName == null || candidateName == "" ? "null" : candidateName
      }`,
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.response.length == 0) {
          setScrollMore(false);
        } else {
          setScrollMore(true);
        }
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...searchSuggestionList, ...response.data.response];
          setSearchSuggestionList(searchedList);
        }
        setSuggestionListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSuggestionListLoading(false);
      })
      .finally(() => {
        setSuggestionListLoading(false);
      });
  };
  const onShowmoreClicked = () => {
    if (scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      const candidateName = decoded.UserName;
      if (decoded.isExistsInCandidate == "True") {
        let candidateName = decoded.UserName;
        setCandidateName(candidateName);
        onNameSearchSuggestion(fullName, nextPageNumber, false, "", candidateName, 0);
      } else {
        let workerId = decoded.Id;
        setWorkerId(workerId);
        onNameSearchSuggestion(fullName, nextPageNumber, false, "", "", decoded.Id);
      }
    }
  };
  
  const onEditVoterDetails = (voterId) => {
    setEditVoterId(voterId);
    setShowEditMode(true);
    setShowFamilyCards(false);
  };
  const onCancelEditVoterDetails = (voterId) => {
    setEditVoterId(voterId);
    setShowEditMode(false);
  };
  const onAgeNumberChange = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].age = e.target.value;
    setSearchSuggestionList(updatedList);
  };
  const onPhoneNumberChange = (e, number) => {
    const value = e.target.value;
    const re = /^[+-]?\d*(?:[.,]\d*)?$/;
    const numericValue = value.replace(/\D/g, "");

    if (re.test(numericValue)) {
      if (numericValue.length > 10) {
        return;
      }
      const updatedList = [...searchSuggestionList];
      updatedList[number].mobileNo = numericValue;
      setSearchSuggestionList(updatedList);
    }
  };
  const onselectGender = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].gender = e.target.value;
    setSearchSuggestionList(updatedList);
  };
  const onselectCaste = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].caste = e.target.value;
    setSearchSuggestionList(updatedList);
  };
  const onselectConfirmation = (e, number, val) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].votingConfirmation = e.target.value;
    setSearchSuggestionList(updatedList);
  };
  const onNewAddressChange = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].changedAddress = e.target.value;
    setSearchSuggestionList(updatedList);
  };
  const onBirthDateChange = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].birthDate = e.target.value;
    setSearchSuggestionList(updatedList);
  };

  const onFamilyMemberChange = (e, number) => {
    if (e.target.value > 25) {
      toast.current.show({ severity: "error", detail: "Family member should be less than 25 ", life: 3000 });
      // return;
    }

    const updatedList = [...searchSuggestionList];
    updatedList[number].familyMemberCount = e.target.value;
    setSearchSuggestionList(updatedList);
  };
  const onHouseNumberChange = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].houseNumber = e.target.value;
    setSearchSuggestionList(updatedList);
  };
  const onMaritalStatusClick = (e, number) => {
    let setValueOfMarriedStatus;
    if (e.target.checked == true) {
      setValueOfMarriedStatus = "विवाहित";
    } else {
      setValueOfMarriedStatus = "अविवाहित";
    }
    const updatedList = [...searchSuggestionList];
    updatedList[number].maritalStatus = setValueOfMarriedStatus;
    setSearchSuggestionList(updatedList);
  };
  const onVisitedStatusClick = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].visited = e.target.checked;
    setSearchSuggestionList(updatedList);
  };
  const onFamilyMemberCheckboxClick = (item, number) => {
    let newFamilyMemberList = { ...familyList };
    newFamilyMemberList = familyList.map((familyMemberInfo, index) => {
      if (familyMemberInfo.familyInfo.id == item.familyInfo.id) {
        if (item.isFamilyMemberCheck == false) {
          return {
            familyInfo: familyMemberInfo.familyInfo,
            isFamilyMemberCheck: true,
          };
        } else {
          return {
            familyInfo: familyMemberInfo.familyInfo,
            isFamilyMemberCheck: false,
          };
        }
      } else {
        return {
          familyInfo: familyMemberInfo.familyInfo,
          isFamilyMemberCheck: familyMemberInfo.isFamilyMemberCheck,
        };
      }
    });
    setFamilyList(newFamilyMemberList);
  };
  const onShowFamilyMemberButtonClick = async (e, item) => {
    setEditVoterId(item.voterId);
    setShowFamilyCards(true);
    setShowFamilyListLoading(true);
    const familyMemberIds = await getFamilyMember(item.id);
    onFamilyClick(item, familyMemberIds);
  };
  const onShowAllDetailsOfFamily = (id) => {
    setShowFamilyDetailsId(id);
    setShowSeperateFamilyDetailsCard(true);
  };
  const onHideAllDetailsOfFamily = () => {
    setShowSeperateFamilyDetailsCard(false);
    setShowFamilyDetailsId("");
  };
  const onHideFamilyMemberButtonClick = (e, voterid) => {
    setEditVoterId(voterid);
    setShowFamilyCards(false);
  };
  const getFamilyMember = async (familyMemberId) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/getfamilymember?primaryFamilyMemberId=${familyMemberId}`,
    };

    try {
      const response = await axios(config);
      return response.data.response;
    } catch (error) {
      console.log("Error making API request:", error);
    }
  };
  const onFamilyClick = (selectedPrimaryFamilyMember, familyMemberIds) => {
    setShowFamilyCards(true);
    setFamilyList([]);
    setPrimaryFamilyMemberId(selectedPrimaryFamilyMember.id);
    const data = {
      firstName: selectedPrimaryFamilyMember.firstName,
      middleName: selectedPrimaryFamilyMember.middleName,
      lastName: selectedPrimaryFamilyMember.lastName,
      workerId: 0,
      voterId: selectedPrimaryFamilyMember.voterId,
    };
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/searchfamilymember?candidateName=${candidateName == "null" ? "" : candidateName}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        let familyList = [];
        let updatedFamilyMemberList = [];
        response.data.response.forEach((item) => {
          familyList.push({
            familyInfo: item,
            isFamilyMemberCheck: false,
          });
        });
        const idSet = new Set(familyMemberIds);
        updatedFamilyMemberList = familyList.map((item) => {
          if (idSet.has(item.familyInfo.id)) {
            return { ...item, isFamilyMemberCheck: true };
          }
          return item;
        });

        setFamilyList(updatedFamilyMemberList);
        setShowFamilyListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShowFamilyListLoading(false);
      })
      .finally(() => {});
  };
  const checkValidation = (item) => {
    let returnValue = true;
    const value = item.mobileNo;
    const re = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (value != "" && value != null) {
      const numericValue = value.replace(/\D/g, "");

      if (numericValue !== null && numericValue !== "" && re.test(numericValue)) {
        if (numericValue.length < 10 || numericValue.length > 10) {
          toast.current.show({ severity: "error", detail: "Please enter a valid phone number number ", life: 3000 });
          returnValue = false;
        }
      }
    }
    if (item.familyMemberCount > 25) {
      toast.current.show({ severity: "error", detail: "Family member should be less than 25", life: 3000 });

      returnValue = false;
    }
    return returnValue;
  };
  const onUpdateVoterDetails = async (item, index) => {
    if (checkValidation(item)) {
      let isExistsInCandidate = false;
      if (localStorage.getItem("token") !== null) {
        const token = localStorage.getItem("token");
        const decoded = jwtDecode(token);
        isExistsInCandidate = decoded.isExistsInCandidate;
      }
      const data = {
        id: item.id,
        workerId: workerId,
        candidateId: candidateId,
        caste: item.caste,
        mobileNo: item.mobileNo,
        familyMemberCount: item.familyMemberCount,
        houseNumber: item.houseNumber,
        birthDate: item.birthDate != "" && item.birthDate != null ? moment(item.birthDate).format("YYYY-MM-DDTHH:mm:ss") : null,
        changedAddress: item.changedAddress,
        votingConfirmation: item.votingConfirmation,
        maritalStatus: item.maritalStatus,
        visited: item.visited,
      };
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/updatevoter?isExistsInCandidate=${isExistsInCandidate}`,
        data: data,
      };

      try {
        const response = await axios(config);
        if (response.data.response == true) {
          toast.current.show({ severity: "success", detail: "तपशील यशस्वीरित्या अपडेट केले", life: 3000 });
          setEditVoterId(null);
        } else {
          toast.current.show({ severity: "error", detail: " While Details updating", life: 3000 });
        }
      } catch (error) {
        console.log("Error making API request:", error);
      }
    }
  };
  const onAddOrUpdateFamilyMemberClick = () => {
    setAddingFamilyLoading(true);
    let selectedFamilyListIds = [];
    familyList.forEach((element) => {
      if (element.isFamilyMemberCheck == true) {
        selectedFamilyListIds.push(element.familyInfo.id);
      }
    });
    const data = {
      primaryFamilyMemberId: primaryFamilyMemberId,
      fmailyMemberId: selectedFamilyListIds,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/addfamilymember`,
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.response == true) {
          toast.current.show({ severity: "success", detail: "कुटुंब यशस्वीरित्या अद्ययावत", life: 3000 });
          // setEditVoterId(null);
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error  while adding family member", life: 3000 });
        }
        setAddingFamilyLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAddingFamilyLoading(false);
      });
  };
  return (
    <div className="app main-container-login">
      <Toast ref={toast} />
      <div className="header">
        <div className="row mt-2">
          <div className="col-10">
            <label htmlFor="Constituency" className="form-label Polling text-light">
              मतदार यादी
            </label>
          </div>
          <div className="col-2 text-end">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>

        <div className="input-group mb-2 mt-2" style={{ zIndex: "0" }}>
          <input
            type="text"
            className="form-control  input-box "
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-icon"
            value={fullName}
            onChange={(e) => onChangeFirstName(e.target.value)}
            onKeyDown={(e) => {
              const firstCharBlockedKeys = [32];
              if (e.target.value.length === 0 && firstCharBlockedKeys.includes(e.keyCode)) {
                e.preventDefault();
              }
            }}
          />
          <i className="fas fa-search" style={{ position: "absolute", top: "14px", right: "12px" }}></i>
        </div>
      </div>

      <div className="container ">
        <BottomScrollListener onBottom={onShowmoreClicked}>
          {(scrollRef) => (
            <div className="mt-2" ref={scrollRef} style={{ height: "650px", overflowY: "auto", overflowX: "hidden" }}>
              {searchSuggestionList.map((item, number) => (
                <div className=" card mx-auto voter-list-card mb-2" key={number}>
                  <div className="row d-flex  mx-auto ">
                    <div className="col-4 ps-0 mb-1">
                      <span className="fw-bold voter-list-label"> {item.slipNumber}</span>
                    </div>
                    <div className="col-6 p-0 mb-1">
                      <span className="voter-list-answer">{item.voterId}</span>
                    </div>
                    <div className="col-2 d-flex justify-content-end text-12 mb-1 pe-1">
                      {showEditMode && editVoterId == item.voterId ? null : (
                        <i class="pi pi-pencil show-cursor text-light" title="Edit" onClick={() => onEditVoterDetails(item.voterId)}></i>
                      )}
                    </div>
                    <div className="col-4 ps-0 mb-1">
                      <span className="fw-bold voter-list-label">नाव</span>
                    </div>
                    <div className="col-8 ps-0 mb-1">
                      <span className="voter-list-answer">
                        {item.lastName} {item.firstName} {item.middleName}
                      </span>
                    </div>

                    {showEditMode && editVoterId == item.voterId && (
                      <>
                        <div className="col-4 ps-0 mb-1">
                          <span className="fw-bold voter-list-label">फोन नंबर</span>
                        </div>
                        <div className="col-8 ps-0 mb-1">
                          {showEditMode && editVoterId == item.voterId ? (
                            <input
                              type="number"
                              className="w-100 form-control input-box mb-1 mt-1 "
                              value={item.mobileNo}
                              name="mobileNo"
                              onChange={(e) => onPhoneNumberChange(e, number)}
                              onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                            />
                          ) : (
                            <span className="voter-list-answer">{item.mobileNo}</span>
                          )}
                        </div>
                      </>
                    )}
                    <div className="col-4 ps-0 mb-1">
                      <span className="fw-bold voter-list-label">पत्ता</span>
                    </div>
                    <div className="col-8 ps-0 mb-1">
                      <span className="voter-list-answer">
                        {item.wordName}, {item.district}
                      </span>
                    </div>
                    <div className="col-4 ps-0 mb-1">
                      <span className="fw-bold voter-list-label">नवीन पत्ता</span>
                    </div>
                    <div className="col-8 ps-0 mb-1">
                      {showEditMode && editVoterId === item.voterId ? (
                        <input
                          type="text"
                          className="w-100 form-control input-box  mb-1"
                          value={item.changedAddress}
                          name="changedAddress"
                          onChange={(e) => onNewAddressChange(e, number)}
                        />
                      ) : (
                        <span className="voter-list-answer">{item.changedAddress}</span>
                      )}
                    </div>
                    <div className="col-4 ps-0 mb-1">
                      <span className="fw-bold voter-list-label">घर क्रमांक</span>
                    </div>
                    <div className="col-8 ps-0 mb-1">
                      {showEditMode && editVoterId === item.voterId ? (
                        <input
                          type="text"
                          className="w-100 form-control input-box mb-1"
                          value={item.houseNumber}
                          name="houseNumber"
                          onChange={(e) => onHouseNumberChange(e, number)}
                        />
                      ) : (
                        <span className="voter-list-answer">{item.houseNumber}</span>
                      )}
                    </div>
                    <div className="col-4 ps-0 mb-1">
                      <span className="fw-bold voter-list-label">वय</span>
                    </div>
                    <div className="col-3 ps-0 mb-1 ">
                      <span className="voter-list-answer">{item.age}</span>
                    </div>
                    <div className="col-2 mb-1">
                      <span className="fw-bold voter-list-label"> लिंग</span>
                    </div>
                    <div className="col-3 ps-0 mb-1">
                      <span className="voter-list-answer">{item.gender}</span>
                    </div>
                    <div className="col-4 ps-0 mb-1">
                      <span className="fw-bold voter-list-label">जन्मदिनांक</span>
                    </div>
                    <div className="col-8 ps-0 mb-1">
                      {showEditMode && editVoterId === item.voterId ? (
                        <input
                          type="date"
                          className="w-100 form-control input-box  mb-1"
                          value={moment(item.birthDate).format("YYYY-MM-DD")}
                          name="birthDate"
                          onChange={(e) => onBirthDateChange(e, number)}
                        />
                      ) : (
                        <span className="voter-list-answer">
                          {item.birthDate != "" && item.birthDate != null ? moment(item.birthDate).format("YYYY-MM-DD") : ""}
                        </span>
                      )}
                    </div>

                    <div className="col-4 ps-0 mb-1">
                      <span className="fw-bold voter-list-label">कुटुंब सदस्य</span>
                    </div>
                    <div className="col-3 ps-0 pe-0 mb-1 ">
                      {showEditMode && editVoterId === item.voterId ? (
                        <input
                          type="number"
                          className="w-100 form-control input-box mb-1"
                          value={item.familyMemberCount}
                          name="familyMember"
                          onChange={(e) => onFamilyMemberChange(e, number)}
                          onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                        />
                      ) : (
                        <span className="voter-list-answer">{item.familyMemberCount}</span>
                      )}
                    </div>
                    <div className="col-2 mb-1">
                      <span className="fw-bold voter-list-label">जात</span>
                    </div>
                    <div className="col-3 ps-0 mb-1">
                      {showEditMode && editVoterId == item.voterId ? (
                        <select
                          name="Caste"
                          value={item.caste || ""}
                          className="form-control form-control input-box mb-1"
                          id="Caste"
                          onChange={(e) => onselectCaste(e, number)}
                        >
                          <option></option>
                          <option value="हिंदू">हिंदू</option>
                          <option value="शीख">शीख</option>
                          <option value="जैन">जैन</option>
                          <option value="बौद्ध">बौद्ध</option>
                          <option value="मुस्लिम">मुस्लिम</option>
                          <option value="ख्रिश्चन">ख्रिश्चन</option>
                        </select>
                      ) : (
                        <span className="voter-list-answer">{item.caste}</span>
                      )}
                    </div>
                    <div className="col-4 ps-0 mb-1 pe-1">
                      <span className="fw-bold voter-list-label">विश्वसनीयता</span>
                    </div>
                    <div className="col-8 ps-0 mb-1">
                      {showEditMode && editVoterId == item.voterId ? (
                        <select
                          name="Confirmation"
                          value={item.votingConfirmation || ""}
                          className="form-control form-select input-box mb-1 "
                          id="confirmation"
                          onChange={(e) => onselectConfirmation(e, number, item.votingConfirmation)}
                        >
                          <option></option>
                          <option value="असू शकते">असू शकते</option>
                          <option value="होय">होय</option>
                          <option value="नाही">नाही</option>
                        </select>
                      ) : (
                        <span className="voter-list-answer">{item.votingConfirmation}</span>
                      )}
                    </div>
                    {item.gender != "पुरुष" ? (
                      <div className="col-4 ps-0 mb-2">
                        <span className="fw-bold voter-list-label">वैवाहिक स्थिती</span>
                        <div className="col-8 ps-0 mb-2">
                          {showEditMode && editVoterId === item.voterId ? (
                            <>
                              <input
                                type="checkbox"
                                id="maritalStatusSwitch"
                                className="checkbox"
                                onChange={(e) => onMaritalStatusClick(e, number)}
                                checked={item.maritalStatus === "Married" ? true : false}
                              />
                              <label
                                htmlFor="maritalStatusSwitch"
                                className={`mb-0 toggle ${
                                  item.maritalStatus === "Married"
                                    ? "justify-content-start checked-toggle-span toggleON"
                                    : "justify-content-end unchecked-toggle-span"
                                }`}
                              >
                                <span className="text-12">
                                  {item.maritalStatus == null ? "अविवाहित" : item.maritalStatus === "Married" ? "विवाहित" : item.maritalStatus}
                                </span>
                              </label>
                            </>
                          ) : (
                            <span className="voter-list-answer">
                              {item.maritalStatus == null ? "अविवाहित" : item.maritalStatus === "Married" ? "विवाहित" : item.maritalStatus}
                            </span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-5  ps-0 d-flex align-items-center mb-1 ">
                      {showEditMode && editVoterId == item.voterId ? (
                        <>
                          <input
                            type="checkbox"
                            id="visitedswitch"
                            className="checkbox"
                            onChange={(e) => onVisitedStatusClick(e, number)}
                            checked={item.visited == true ? true : false}
                          />
                          <label
                            htmlFor="visitedswitch"
                            className={`mb-0 toggle ${
                              item.visited ? "justify-content-start checked-toggle-span toggleON" : "justify-content-end unchecked-toggle-span"
                            }`}
                          >
                            <span className="text-12">{item.visited == true ? "भेट दिली" : "भेट नाही दिली"}</span>
                          </label>
                        </>
                      ) : (
                        <>
                          <input type="checkbox" id="visitedswitch2" className="checkbox" checked={item.visited == true ? true : false} />
                          <label
                            htmlFor="visitedswitch2"
                            className={`mb-0 toggle ${
                              item.visited ? "justify-content-start checked-toggle-span toggleON" : "justify-content-end unchecked-toggle-span"
                            }`}
                          >
                            <span className="text-12">{item.visited == true ? "भेट दिली" : "भेट नाही दिली"}</span>
                          </label>
                        </>
                      )}
                    </div>
                    <div className="col-7 ps-0 pe-0 mb-1 text-end">
                      {showFamilyListLoading && editVoterId == item.voterId ? (
                        <div className="d-flex justify-content-center">
                          <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                        </div>
                      ) : (
                        <>
                          {showFamilyCards && editVoterId == item.voterId ? (
                            <button className="btn  show-btn w-auto text-12" onClick={(e) => onHideFamilyMemberButtonClick(e, item.voterId)}>
                              {" "}
                              कुटुंबातील सदस्य लपवा
                            </button>
                          ) : (
                            <button className="btn  show-btn w-auto text-12" onClick={(e) => onShowFamilyMemberButtonClick(e, item)}>
                              {" "}
                              कुटुंबातील सदस्य दाखवा{" "}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                    {showFamilyCards && editVoterId == item.voterId && showFamilyListLoading == false ? (
                      <div className="row family-card-bg mx-auto py-1 d-flex align-items-center mt-2 ">
                        {familyList.length == 0 ? (
                          <span className=" text-info oops-msg-text text-danger justify-content-center">
                            <b>कुटुंबातील सदस्य सापडत नाही.</b>
                          </span>
                        ) : (
                          familyList.map((member, number) => (
                            <div className="col-12  my-1 text-light" style={{ background: "#5F6368" }}>
                              <div className="row p-1 ">
                                <div className="col-3 pe-0">
                                  {showEditMode && editVoterId == item.voterId ? (
                                    <input
                                      className="form-check-input me-1 mt-0 text-14"
                                      type="checkbox"
                                      onChange={() => onFamilyMemberCheckboxClick(member, number)}
                                      value=""
                                      checked={member.isFamilyMemberCheck}
                                      id=""
                                    />
                                  ) : (
                                    <input
                                      className="form-check-input me-1 mt-0 text-14"
                                      type="checkbox"
                                      value=""
                                      checked={member.isFamilyMemberCheck}
                                      id=""
                                    />
                                  )}
                                  <span>{member.familyInfo.slipNumber}</span>
                                </div>
                                <div className="col-7 pe-0">
                                  <span className="fw-bold ">Voter Id </span>
                                  <span>{member.familyInfo.voterId}</span>
                                </div>

                                <div className="col-2 text-end">
                                  {showSeperateFamilyDetailsCard && showFamilyDetailsId == member.familyInfo.voterId ? (
                                    <i class="pi pi-chevron-up show-cursor text-light text-12" onClick={onHideAllDetailsOfFamily} />
                                  ) : (
                                    <i
                                      class="pi pi-chevron-down show-cursor text-light text-12"
                                      onClick={() => onShowAllDetailsOfFamily(member.familyInfo.voterId)}
                                    />
                                  )}
                                </div>

                                <div className="col-3 mb-2">
                                  <span className="fw-bold ">नाव</span>
                                </div>

                                <div className="col-9">
                                  <span>
                                    {" "}
                                    {member.familyInfo.lastName} {member.familyInfo.firstName} {member.familyInfo.middleName}{" "}
                                  </span>
                                </div>
                                {showSeperateFamilyDetailsCard && showFamilyDetailsId == member.familyInfo.voterId ? (
                                  <>
                                    <div className="row d-flex align-items-center w-100 mb-1 ">
                                      <div className="col-3 pe-0">
                                        <span className="fw-bold ">वय &nbsp; </span>
                                        <span> {member.familyInfo.age} </span>
                                      </div>
                                      <div className="col-4 pe-0">
                                        <span className="fw-bold">लिंग &nbsp; </span>
                                        <span> {member.familyInfo.gender} </span>
                                      </div>
                                      <div className="col-5 px-0 text-end">
                                        <input
                                          type="checkbox"
                                          id="familyMaritalStatusSwitch"
                                          class="checkbox"
                                          checked={member.familyInfo.maritalStatus}
                                        />
                                        <span
                                          htmlFor="familyMaritalStatusSwitch"
                                          className={`fw-bold  family-marital-toggle toggle ${
                                            member.familyInfo.maritalStatus
                                              ? "justify-content-start checked-toggle-span toggleON"
                                              : "justify-content-end unchecked-toggle-span"
                                          }`}
                                        >
                                          <span className="text-10 px-1">{member.familyInfo.maritalStatus ? "विवाहित" : "अविवाहित"}</span>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-3 mb-2">
                                      <span className="fw-bold  ">पत्ता </span>
                                    </div>
                                    <div className="col-9 mb-2">
                                      <span>
                                        {" "}
                                        {member.familyInfo.wordName}, {member.familyInfo.district}{" "}
                                      </span>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <span className="fw-bold me-2">मतदार केंद्र </span>
                                    </div>
                                    <div className="col-9 mb-2">
                                      <span> {member.familyInfo.addressOfPollingStation} </span>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    ) : null}

                    {showEditMode && editVoterId == item.voterId ? (
                      <div className="col-12 d-flex align-items-center justify-content-end mt-2">
                        {addingFamilyLoading ? (
                          <div className="d-inline-block me-2  ">
                            <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                          </div>
                        ) : showFamilyCards && editVoterId == item.voterId && familyList.length > 0 ? (
                          <button className="btn  show-btn w-auto text-12 me-2" onClick={() => onAddOrUpdateFamilyMemberClick(item, number)}>
                            {" "}
                            कुटुंब जोडा
                          </button>
                        ) : null}
                        <button className="btn  show-btn w-auto text-12 me-2" onClick={() => onUpdateVoterDetails(item, number)}>
                          {" "}
                          सादर करा{" "}
                        </button>
                        <button className="btn  show-btn w-auto text-12 " onClick={() => onCancelEditVoterDetails(number)}>
                          {" "}
                          रीसेट{" "}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
              {suggestionListLoading ? (
                <div className="d-flex justify-content-center">
                  <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                </div>
              ) : null}
            </div>
          )}
        </BottomScrollListener>
      </div>
    </div>
  );
}
