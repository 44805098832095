import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import candidateimg from "../Assets/Images/candidatedashboard.svg";
import { jwtDecode } from "jwt-decode";

export default function CandidateDashbord() {
  const [counts, setCounts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token != "" && token != null) {
      const decoded = jwtDecode(token);
      const candidateName = decoded.UserName;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetAllCounts?CandidateName=${candidateName}`,
        // headers: {
        //     Authorization: `Bearer ${token}`
        // }
      };

      axios
        .request(config)
        .then((response) => {
          setCounts(response.data.response);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate("/login");
    }
  }, []);

  const getRecordCount = (tableName) => {
    const record = counts.find((item) => item.tableName === tableName);
    return record ? record.recordCount : "0";
  };
  const onPoolingBoothClick = () => {
    navigate("/pollingbooth ");
  };

  const onVoterlistClick = () => {
    navigate("/displayvoterlist");
  };

  const onVoterbyage = () => {
    navigate("/votersbyage");
  };

  const onAreawiseListClick = () => {
    navigate("/areawiselist");
  };

  const onVoterByeCastClick = () => {
    navigate("/voterbycast");
  };
  const onVisitedVotersClick = () => {
    navigate("/visitedvoters");
  };
  const onChangedAddressVotersClick = () => {
    navigate("/changedaddressvoters");
  };
  const onPollingAreaPlacesClick = () => {
    navigate("/pollingarea");
  };
  const onCategoryVotersClick = () => {
    navigate("/confirmedvoterslist");
  };
  const onDeadVotersClick = () => {
    navigate("/deadvoters");
  };
  const onFieldWorkerlistClick = () => {
    navigate("/fieldworkerlist");
  };
  const onMarriedFemalelistClick = () => {
    navigate("/marriedfemalelist");
  };
  const onSurnamelistClick = () => {
    navigate("/surnamelist");
  };
  const onFamilyAddedVotersClick = () => {
    navigate("/votersfamilylist");
  };
  const onPhoneNumberListClick = () => {
    navigate("/phonenumberlist");
  };
  const onAddedBirthDateListClick = () => {
    navigate("/birthdatelist");
  };
  const onTodaysBirthdayListClick = () => {
    navigate("/birthdaylist");
  };
  const onlogoutClick = () => {
    navigate("/login");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
  };
  const onPollingAgentClick = () => {
    navigate("/pollingAgent");
  };

  const onProfilePageClick = () => {
    navigate("/candidateconfig");
  };

  return (
    <div className="app main-container-login dashboard-main-page">
      <div className="header header-dashboard header-candidate">
        <div className="row">
          <div className="col-1">
            <img src={candidateimg} alt="congress" />
          </div>
          <div className="col-10 text-center mt-1">२८२ सांगली विधानसभा मतदारांची माहिती</div>
          <div className="col-1 mt-2">
            <i className="pi pi-cog" style={{ fontSize: "1rem", marginRight: "20px", cursor: "pointer" }} onClick={onProfilePageClick}></i>
            <i className="pi pi-sign-out" style={{ fontSize: "1rem" }} onClick={onlogoutClick}></i>
          </div>
        </div>
      </div>
      <div className="container-fluid  ">
        <div className="row mx-auto card-row">
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onSurnamelistClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("VoterBySurname")}</span>
                  <br />
                  आडनावा नुसार मतदार
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onVoterbyage}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("YoungAge")}</span>
                  <br />
                  वयानुसार मतदार
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onFamilyAddedVotersClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("TotalFamily")}</span>
                  <br />
                  कुटुंबनुसार मतदार
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onAreawiseListClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("AreaWiseVoter")}</span>
                  <br />
                  क्षेत्रनिहाय मतदार
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onPhoneNumberListClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("PhoneNumber")}</span>
                  <br />
                  मतदार फोन नंबर
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onVoterByeCastClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("Caste")}</span>
                  <br />
                  जात निहाय मतदार
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onVisitedVotersClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("Visited")}</span>
                  <br />
                  भेट झालेले मतदार
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onChangedAddressVotersClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("ChangedAddress")}</span>
                  <br />
                  पत्ता बदल
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onFieldWorkerlistClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("TotalWorkers")}</span>
                  <br />
                  कार्यकर्ता यादी
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onCategoryVotersClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("VotingConfirmation")}</span>
                  <br />
                  मतदारांची वर्गवारी
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onPollingAreaPlacesClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("PollingAreaPlaces")}</span>
                  <br />
                  क्षेत्रातील गावे
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onMarriedFemalelistClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("FemaleMarried")}</span>
                  <br />
                  लग्न झालेल्या महिला
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1 mt-2">
            <div
              className="card show-cursor"
              onClick={onPoolingBoothClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("TotalBooths")}</span>
                  <br />
                  एकूण मतदान केंद्राची यादी
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 p-1 mt-2">
            <div
              className="card show-cursor"
              onClick={onVoterlistClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("VoterList")}</span>
                  <br />
                  मतदार यादी (एकूण मतदार)
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onTodaysBirthdayListClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("TodaysBirthday")}</span>
                  <br />
                  आजचे वाढदिवस
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onAddedBirthDateListClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("AddedBirthDate")}</span>
                  <br />
                  वाढदिवसाची यादी
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onDeadVotersClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("DeadVoter")}</span>
                  <br />
                  मयत मतदार
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="card show-cursor"
              onClick={onPollingAgentClick}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            >
              <div className="count-container" style={{ textAlign: "center" }}>
                <div className="count-text">
                  <span className="count">{getRecordCount("")}</span>
                  <br />
                  मतदान दिवसाचे व्यवस्थापन
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
