import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardBanner from "../Assets/Images/JSDashboard.svg";
import photo from "../Assets/Images/JayashreetaiMadanbhauPatil 1.svg";
import headerIcon from "../Assets/Images/Icon.svg";
import { jwtDecode } from "jwt-decode";

export default function Login(props) {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let errors = {};
    if (!userName) errors.userName = "वापरकर्ता नाव आवश्यक आहे.";
    if (!password) errors.password = "पासवर्ड आवश्यक आहे.";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const clearValues = () => {
    localStorage.removeItem("token");
  };
  const onLogin = async () => {
    clearValues();
    if (!validate()) {
      toast.error("कृपया सर्व फील्ड योग्यरित्या भरा.");
      return;
    }
    setLoading(true);
    let data = JSON.stringify({ userName, password });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Login/Login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      const token = response.data.response.token;
      const userID = response.data.response.email;
      const userName = response.data.response.userName;

      localStorage.setItem("token", token);
      localStorage.setItem("userId", userID);
      localStorage.setItem("userName", userName);
      const decoded = jwtDecode(token);

      navigate("/candidatedashboard");
      if (decoded.isExistsInCandidate == "True") {
        navigate("/candidatedashboard");
      } else {
        navigate("/displayvoterlist");
      }
    } catch (error) {
      console.error(error);
      toast.error("अवैध वापरकर्ता नाव आणि पासवर्ड");
    } finally {
      setLoading(false);
    }
  };
  const onEnterClick = (e) => {
    if (e.keyCode == 13) {
      onLogin();
    }
  };
  return (
    <div className="login-page">
      <div className="header header-dashboard">
        <div className="row desktop-img">
          <div className="col-3">
            <img src={photo} alt="Scanner image" />
          </div>
          <div className="col-6 header-middle-title">
            <div className="smaller-text">२८२ सांगली विधानसभा मतदार संघातील कौग्रेस पक्षाचे अधिकृत उमेदवार </div>
            <div className="large-text">
              श्रीमती जयश्रीताई मदनभाऊ पाटील यांना <br />
              प्रचंड बहुमताने निवडून द्या !
            </div>
          </div>
          <div className="col-3 text-end" style={{ padding: "0px" }}>
            {" "}
            <img src={headerIcon} alt="Scanner image" style={{ marginRight: "13px" }} />{" "}
          </div>
        </div>
      </div>
      <div>
        <img src={DashboardBanner} alt="Scanner image" className="mobile-img" />
      </div>
      <div className="container">
        <div className=" mt-4 text-center">
          <label htmlFor="Constituency" className="form-label Constituency text-light">
            282 सांगली विधानसभा मतदारसंघ
          </label>
          <form>
            <div className="mb-3 mt-4">
              <input
                type="text"
                className={` form-control  ${errors.userName ? "is-invalid " : ""}`}
                id="voterIdInput"
                placeholder="वापरकर्ता नाव"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              {errors.userName && <div className="invalid-feedback text-start">{errors.userName}</div>}
            </div>
            <div className="mb-2">
              <input
                type="password"
                className={` form-control ${errors.password ? "is-invalid" : ""}`}
                id="passwordInput"
                placeholder="पासवर्ड"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={onEnterClick}
              />
              {errors.password && <div className="invalid-feedback text-start">{errors.password}</div>}
            </div>
          </form>
          <div className="row mt-4 d-flex align-items-center">
            <div className="col-7 d-flex align-items-center">
              <div className="form-check  ">
                <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                <label className="form-check-label mt-1  text-light " htmlFor="flexCheckDefault">
                  लक्षात ठेवा
                </label>
              </div>
            </div>
            <div className="col-5 text-end mt-4">
              {loading ? (
                <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> लॉगिन
                </button>
              ) : (
                <button type="button" className="btn btn-primary confirm-btn" onClick={onLogin}>
                  लॉगिन
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
