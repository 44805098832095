import React, { useEffect, useState, useCallback, useRef } from "react";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toInteger } from "lodash";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";

export default function VotersBySurname() {
  const navigate = useNavigate();
  const [pollingAddress, setPollingAddress] = useState("");
  const [boothAddress, setBoothAddress] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [boothAddressList, setBoothAddressList] = useState([]);
  const [pollingAddressList, setPollingAddressList] = useState([]);
  const [surnameList, setSurnameList] = useState([]);
  const [candidateName, setCandidateName] = useState("");
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [surname, setSurname] = useState(null);
  const [fromBoothNumber, setfromBoothNumber] = useState(0);
  const [fromPollingAddress, setFromPollingAddress] = useState("");
  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getPollingAddresses(decoded.UserName);
      getBoothAddresses(decoded.UserName);
      getSurnameList(decoded.UserName);
    }
  }, []);

  const onSelectFromAddressOfPolling = (boothNo, address) => {
    setfromBoothNumber(boothNo);
    setFromPollingAddress(address);
  };

  const getSurnameList = (candidateName) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getallsurname?candidateName=${candidateName}`,
    };

    axios(config)
      .then((response) => {
        setSurnameList(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  const getBoothAddresses = (candidateName) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getboothaddress?candidateName=${candidateName}`,
    };

    axios(config)
      .then((response) => {
        let boothAddressList = response.data.response;
        setBoothAddressList(boothAddressList);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  const getPollingAddresses = (candidateName) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetDropDownValue?candidateName=${candidateName}`,
    };

    axios(config)
      .then((response) => {
        let pollingAddressList = response.data.response;
        setPollingAddressList(pollingAddressList);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };
  const onSelectOfAddressOfPolling = (item) => {
    setPollingAddress(item);
  };
  const onSelectOfAddressBooth = (item) => {
    setBoothAddress(item);
  };
  const onSelectOfSurname = (item) => {
    setSurname(item);
  };
  const onAllCheckboxSelect = (e) => {
    setIsChecked(e.target.checked);
    setPollingAddress("");
    setBoothAddress("");
    setVoterDetailsList([]);
    setTotalCount(0);
    setSurname("");
  };
  const onSearchClick = (pageno, replace) => {
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);

    const data = {
      searchResultCount: 10,
      searchPageNumber: pageno,
      lastName: surname,
      fromBoothNumber: parseInt(fromBoothNumber),
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getvoterinfobylastname?candidateName=${candidateName}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
        }
        setShowVoterInfo(true);
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };
  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      onSearchClick(nextPageNumber, false);
    }
  };
  return (
    <div className="app main-container-login voter-by-age-page">
      <div className="header">
        <div className="row mt-2">
          <div className="col-10">
            <label htmlFor="Constituency" className="form-label Polling text-light">
              आडनावानुसार मतदार
            </label>
          </div>
          <div className="col-2 text-end">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>

        <div className="col-12 text-14">
          <label className="polling-booth-label">मतदान केंद्र निवडा</label>
          <select
            name="fromPollingAddress"
            value={fromPollingAddress}
            className={`form-control form-select mb-1 text-14 polling-select ${
              fromPollingAddress === "" || fromPollingAddress == null ? "option-as-placeholder" : ""
            }`}
            id="pollingAddress"
            onChange={(e) => {
              const selectedValue = e.target.value;
              const boothNumber = selectedValue.split("-")[0];

              onSelectFromAddressOfPolling(boothNumber, selectedValue);
            }}
          >
            <option className="option-as-placeholder" value="">
              मतदान केंद्र
            </option>
            {pollingAddressList.map((item, index) => (
              <option value={`${item.boothNumber}-${item.pollingAddress}`} key={index}>
                {item.boothNumber}-{item.pollingAddress}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 text-14 mt-1">
          <label className="polling-booth-label">Select surname</label>
          <select
            name="surname"
            value={surname}
            className={`form-control form-select mb-1 text-14 polling-select ${surname === "" || surname == null ? "option-as-placeholder" : ""}`}
            id="surname"
            onChange={(e) => onSelectOfSurname(e.target.value)}
          >
            <option className="option-as-placeholder" value="">
              Select surname
            </option>
            {surnameList.map((item, index) => (
              <option key={index} value={item.lastName}>
                {item.lastName} - {item.count}
              </option>
            ))}
          </select>
        </div>
        <div className="row  d-flex align-items-center">
          <div className="col-12 mt-2 text-end ">
            <button className="btn  show-btn w-auto text-12" onClick={() => onSearchClick(1, true)}>
              {" "}
              Search
            </button>
          </div>
        </div>
        {showVoterInfo ? (
          voterDetailsList.length > 0 ? (
            <BottomScrollListener onBottom={onShowmoreClicked}>
              {(scrollRef) => (
                <div className="mt-2" ref={scrollRef} style={{ height: "500px", overflowY: "auto", overflowX: "hidden" }}>
                  {voterDetailsList.map((item, number) => (
                    <div
                      className={
                        voterDetailsList.length === number + 1 && votersListLoading === false
                          ? "card voter-list-card last-card-bottom mb-2"
                          : "card voter-list-card mb-2"
                      }
                      key={number}
                    >
                      <div className="row d-flex align-items-center mx-auto">
                        <div className="col-4 ps-0 mb-1">
                          <span className="fw-bold voter-list-label">{item.slipNumber}</span>
                        </div>
                        <div className="col-5 p-0 mb-1">
                          <span className="voter-list-answer">{item.voterId}</span>
                        </div>

                        <div className="col-4 ps-0 mb-1">
                          <span className="fw-bold voter-list-label">नाव</span>
                        </div>
                        <div className="col-8 ps-0 mb-1">
                          <span className="voter-list-answer">
                            {item.lastName} {item.firstName} {item.middleName}
                          </span>
                        </div>

                        <div className="col-4 ps-0 mb-1">
                          <span className="fw-bold voter-list-label">फोन नंबर</span>
                        </div>
                        <div className="col-8 ps-0 mb-1">
                          <span className="voter-list-answer">{item.mobileNo}</span>
                        </div>

                        <div className="col-4 ps-0 mb-1">
                          <span className="fw-bold voter-list-label">पत्ता</span>
                        </div>
                        <div className="col-8 ps-0 mb-1">
                          <span className="voter-list-answer">
                            {item.wordName}, {item.district}
                          </span>
                        </div>

                        <div className="col-4 ps-0 mb-1">
                          <span className="fw-bold voter-list-label">घर क्रमांक</span>
                        </div>
                        <div className="col-8 ps-0 mb-1">
                          <span className="voter-list-answer">{item.houseNumber}</span>
                        </div>

                        <div className="col-4 ps-0 mb-1">
                          <span className="fw-bold voter-list-label">वय</span>
                        </div>
                        <div className="col-3 ps-0 mb-1">
                          <span className="voter-list-answer">{item.age}</span>
                        </div>

                        <div className="col-2 mb-1">
                          <span className="fw-bold voter-list-label">लिंग</span>
                        </div>
                        <div className="col-3 ps-0 mb-1">
                          <span className="voter-list-answer">{item.gender}</span>
                        </div>

                        <div className="col-4 ps-0 mb-1">
                          <span className="fw-bold voter-list-label">जन्मदिनांक</span>
                        </div>
                        <div className="col-8 ps-0 mb-1">
                          <span className="voter-list-answer">
                            {item.birthDate !== "" && item.birthDate !== null ? moment(item.birthDate).format("YYYY-MM-DD") : ""}
                          </span>
                        </div>

                        <div className="col-4 ps-0 mb-1">
                          <span className="fw-bold voter-list-label">कुटुंब सदस्य</span>
                        </div>
                        <div className="col-3 ps-0 pe-0 mb-1">
                          <span className="voter-list-answer">{item.familyMemberCount}</span>
                        </div>

                        <div className="col-2 mb-1">
                          <span className="fw-bold voter-list-label">जात</span>
                        </div>
                        <div className="col-3 ps-0 mb-1">
                          <span className="voter-list-answer">{item.caste}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </BottomScrollListener>
          ) : (
            <div className="mt-2  voter-list-label text-center">No data available</div>
          )
        ) : null}

        {votersListLoading ? (
          <div className="d-flex justify-content-center">
            <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
