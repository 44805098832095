import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import share from "../Assets/Images/sharevoter.svg";
import print from "../Assets/Images/print.svg";
import { ThreeDots } from "react-loader-spinner";
export default function PollingBooth() {
  const navigate = useNavigate();
  const [candidateName, setCandidateName] = useState("");
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [pollingAddressList, setPollingAddressList] = useState([]);
  const [toPollingAddress, setToPollingAddress] = useState("");
  const [fromPollingAddress, setFromPollingAddress] = useState("");
  const [boothList, setBoothListList] = useState([]);
  const [fromBoothNumber, setfromBoothNumber] = useState(0);
  const [toBoothNumber, settoBoothNumber] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getPollingBoothList(candidateName);
      getPollingBoothListCount(candidateName);
    }
  }, []);

  const getPollingBoothList = (candidateNm) => {
    setVotersListLoading(true);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetDropDownValue?candidateName=${candidateNm}`,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          setPollingAddressList(response.data.response);
        }
      })
      .catch((error) => {
        console.log(error);
        setVotersListLoading(false);
      })
      .finally(() => {});
  };

  const getPollingBoothListCount = (candidateNm) => {
    setVotersListLoading(true);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getBoothDetailscount?candidateName=${candidateNm}&FromBoothNumber=${fromBoothNumber}&ToBoothNumber=${toBoothNumber}`,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          setBoothListList(response.data.response);
        }
        setfromBoothNumber(0);
        settoBoothNumber(0);
      })
      .catch((error) => {
        console.log(error);
        setVotersListLoading(false);
      })
      .finally(() => {});
  };

  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  const onSelectFromAddressOfPolling = (boothNo, address) => {
    setfromBoothNumber(boothNo);
    setFromPollingAddress(address);
  };
  const onSelectToAddressOfPolling = (boothNo, address) => {
    settoBoothNumber(boothNo);
    setToPollingAddress(address);
  };
  const onSearchClick = () => {
    getPollingBoothListCount(candidateName);
  };

  const onClearClick = () => {
    setFromPollingAddress("");
    setToPollingAddress("");
    setfromBoothNumber(0);
    settoBoothNumber(0);
    getPollingBoothListCount(candidateName, 0, 0);
  };

  return (
    <div className="app main-container-login">
      <div className="header">
        <div className="row mt-2">
          <div className="col-10">
            <label htmlFor="Constituency" className="form-label Polling text-light">
              मतदान केंद्र
            </label>
          </div>
          <div className="col-2 text-end">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-6">
            <label className="polling-booth-label">पासून</label>
            <select
              name="fromPollingAddress"
              value={fromPollingAddress}
              className={`form-control form-select mb-1 text-14 polling-select ${
                fromPollingAddress === "" || fromPollingAddress == null ? "option-as-placeholder" : ""
              }`}
              id="pollingAddress"
              onChange={(e) => {
                const selectedValue = e.target.value;
                const boothNumber = selectedValue.split("-")[0];

                onSelectFromAddressOfPolling(boothNumber, selectedValue);
              }}
            >
              <option className="option-as-placeholder" value="">
                मतदान केंद्र
              </option>
              {pollingAddressList.map((item, index) => (
                <option value={`${item.boothNumber}-${item.pollingAddress}`} key={index}>
                  {item.boothNumber}-{item.pollingAddress}
                </option>
              ))}
            </select>
          </div>

          <div className="col-6">
            <label className="polling-booth-label">पर्यंत</label>

            <select
              name="toPollingAddress"
              value={toPollingAddress}
              className={`form-control form-select mb-1 text-14 polling-select ${
                toPollingAddress === "" || toPollingAddress == null ? "option-as-placeholder" : ""
              }`}
              id="pollingAddress"
              onChange={(e) => {
                const selectedValue = e.target.value;
                const boothNumber = selectedValue.split("-")[0];

                onSelectToAddressOfPolling(boothNumber, selectedValue);
              }}
            >
              <option className="option-as-placeholder" value="">
                मतदान केंद्र
              </option>
              {pollingAddressList.map((item, index) => (
                <option value={`${item.boothNumber}-${item.pollingAddress}`} key={index}>
                  {item.boothNumber}-{item.pollingAddress}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <button className="btn show-btn w-auto text-15 me-2" onClick={onSearchClick}>
                शोधा
                {/* <img src={searchimg} alt="Search" style={{ marginLeft: "5px" }} /> */}
              </button>
              <button className="btn show-btn w-auto text-15 me-2" onClick={onClearClick}>
                रीसेट
                {/* <img src={searchimg} alt="Search" style={{ marginLeft: "5px" }} /> */}
              </button>
            </div>
            <div className="d-flex">
              <button className="btn w-auto text-15 text-white me-2">
                <img src={print} alt="Print" style={{ marginLeft: "5px" }} /> प्रिंट
              </button>
              <button className="btn w-auto text-15 text-white">
                <img src={share} alt="Share" style={{ marginLeft: "5px" }} /> शेअर
              </button>
            </div>
          </div>
        </div>

        {!votersListLoading ? (
          boothList.map((item, index) => (
            <div key={index} className="card voter-info-card text-light text-14">
              <div className="row mx-auto border-bottom pb-1">
                <div className="col-3">
                  <span>{item.boothNumber}</span>
                  <br />
                  <span>सांगली</span>
                </div>
                <div className="col-9">
                  <span>{item.pollingAddress}</span>
                </div>
              </div>
              <div className="row mx-auto mt-1">
                <div className="col-3">
                  <span>पुरुष {item.maleCount}</span>
                </div>
                <div className="col-3">
                  <span>स्त्री {item.feMaleCount}</span>
                </div>
                <div className="col-3">
                  <span>तृतिय {item.thirdGenderCount}</span>
                </div>
                <div className="col-3">
                  <span>Total {item.totalCountGender}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            {" "}
            <div className="d-flex justify-content-center">
              <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
