import React, { useCallback, useEffect, useRef } from "react";
import { NavLink, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Header from "./Header";
import { useState } from "react";
import axios from "axios";
import Back from "../Assets/Images/backbtn.svg";
import Home from "../Assets/Images/home_icon.svg";
import photo from "../Assets/Images/JayashreetaiMadanbhauPatil 1.svg";
import dialogImg from "../Assets/Images/MainBanner.svg";
import handWithPhoto from "../Assets/Images/PramotionImage.svg";
import headerIcon from "../Assets/Images/Icon.svg";
import muteicon from "../Assets/Images/mute.svg";
import Soundimage from "../Assets/Images/Soundimage.svg";
import soundFile from "../Sound/JayashritaiMarathiMaleVoice_New.mp3";
import cameraicon from "../Assets/Images/camera_icon.svg";
import btnimg from "../Assets/Images/button.svg";
import handimg from "../Assets/Images/hand.svg";
import Ellipsis from '../Assets/Images/Ellipse.png'
import { Dialog } from "primereact/dialog";
import Namskar from "../Assets/Images/Namskar.svg";
import { jwtDecode } from "jwt-decode";
import _ from "lodash";
import Webcam from "react-webcam";
import newdashboardbanner from "../Assets/Images/Blue Banner_JaysshriTaiPatil 1.jpg";
import WhiteWPImage from "../Assets/Images/white-wp.svg";
import familyicon from "../Assets/Images/Familyicon.svg";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import { Toast } from "primereact/toast";
import { VscLaw } from "react-icons/vsc";
import whatsAppImageBanner from "../Assets/Images/InnerPageBanner.png";
import { InputSwitch } from "primereact/inputswitch";

export default function VoterForm(props) {
  const [isFamilyCardOpen, setIsfamilyCardOpen] = useState(false);
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const location = useLocation();
  const [slipNumber, setSlipNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [voterId, setVoterId] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [searchResultList, setSearchResultList] = useState([]);
  const [searchSuggestionList, setSearchSuggestionList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [cityAndVillageName, setCityAndVillageName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("selectedLanguage") || "Marathi");
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(soundFile));
  const [visible, setVisible] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const scanned = searchParams.get("scanned");
  const name = searchParams.get("name");
  const [WorkerId, setWorkerId] = useState(0);
  const [fullName, setFullName] = useState("");
  const [showWebCam, setShowWebCam] = useState(false);
  const [isWebCamOn, setIsWebCamOn] = useState(true);
  const [scanLoadingIcon, setScanLoadingIcon] = useState(false);
  const [image, setImage] = useState(null);
  const [facingMode, setFacingMode] = useState("environment");
  const [wordName, setWordName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [husbandName, setHusbandName] = useState("");
  const [district, setDistrict] = useState("");
  const [taluka, setTaluka] = useState("");
  const [town, setTown] = useState("");
  const [addressOfPollingStation, setAddressOfPollingStation] = useState("");
  const [caste, setCaste] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [suggestionListLoading, setSuggestionListLoading] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [editVoterId, setEditVoterId] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [scrollMore, setScrollMore] = useState(true);
  const toast = useRef("");
  const [isSharedNumberCardOpen, setIsSharedNumberCardOpen] = useState();
  const [familyList, setFamilyList] = useState([]);
  const [showCardId, setShowCardId] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [primaryFamilyMemberId, setPrimaryFamilyMemberId] = useState(0);
  const [whatsAppError, setWhatsAppError] = useState("");
  const [addingFamilyLoading, setAddingFamilyLoading] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [showFamilyListLoading, setShowFamilyListLoading] = useState(false);
  const [showFirstLandingPage, setFirstNameLandingPage] = useState(true);
  const [Id, setWhatsappID] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to store selected item
  const [selectedNumber, setSelectedNumber] = useState(null); // State to store selected number
  const inputRef = useRef(null);
  const [candidateData, setCandidateData] = useState(null);
  const [errorMessageForMobileNo, setErrorMessageForMobileNo] = useState('');


  useEffect(() => {
    if (scanned == "true") {
      localStorage.removeItem("token");
      setVisible(true);
    } else {
      setVisible(false);
      setFirstNameLandingPage(false);
    }
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      const workerId = decoded.Id;
      setWorkerId(workerId);
      onNameSearchSuggestion('', pageNumber, false, '', workerId, '');
    } else {
      setWorkerId(null);
      onNameSearchSuggestion('', pageNumber, false, '', 0, '');
    }
  }, []);

  useEffect(() => {
    const audio = audioRef.current;

    const playSound = () => {
      audio.play().catch((error) => {
        console.error("Failed to play audio:", error);
      });
      setIsPlaying(true);
    };
    const pauseSound = () => {
      audio.pause();
      setIsPlaying(false);
    };
    if (isPlaying) {
      playSound();
    } else {
      pauseSound();
    }
    return () => {
      audio.pause();
    };
  }, [isPlaying, soundFile]);

  const togglePlay = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  const onChangeVoterId = (value) => {
    if (value.length > 10) {
      return;
    }
    const value1 = value.trim();
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setFullName("");
    setSearchSuggestionList([]);
    if (checkVoterIdNumberValidation(value1)) {
      setVoterId(value1);
      onNameSearchSuggestion('', 1, true, value1, 0, '');
      setErrorMessage("");
    } else {
      if (value1 === "") {
        setVoterId(value1);
        onNameSearchSuggestion('', 1, true, '', 0, '');
        setErrorMessage("");
      } else {
        setVoterId(value1);
        setErrorMessage("कृपया वैध मतदार क्रमांक प्रविष्ट करा");
      }
    }
  };

  const onChangeMobileNo = (value) => {
    debugger;
    if (value.length > 10) {
      return;
    }

    const value1 = value.trim();
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setFullName('');
    setSearchSuggestionList([]);
    setVoterId("");
    setErrorMessage('');
    if (checkMobileNumberValidation(value1)) {
      setMobileNo(value1);
      onNameSearchSuggestion('', 1, true, '', 0, value1);
      setErrorMessageForMobileNo("");
    } else {
      if (value1 === "") {
        setMobileNo(value1);
        onNameSearchSuggestion('', 1, true, '', 0, '');
        setErrorMessageForMobileNo("");
      } else {
        setMobileNo(value1);
        setErrorMessageForMobileNo('कृपया वैध मोबाइल नंबर प्रविष्ट करा');
      }
    }
  }


  const onChangeFirstName = (value) => {
    let replaceValue;
    if (value != fullName || fullName == "") {
      replaceValue = true;
    } else {
      replaceValue = false;
    }

    let workerId = 0;
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      workerId = decoded.Id;
    }
    setVoterId("");
    setSearchSuggestionList([]);
    setIsfamilyCardOpen(false);
    setIsConfirmed(false);
    setWhatsAppError("");
    setIsSharedNumberCardOpen(false);
    setIsfamilyCardOpen(false);
    setFullName(value);
    let name = value.split(" ");
    setFirstName(name[0]);
    setMiddleName(name[1]);
    setLastName(name[2]);
    setErrorMessage("");
    setErrorMessageForMobileNo('')
    setMobileNo('');
    setPageNumber(1);
    if (value) {
      debouncedFetchResults(value, 1, workerId, replaceValue);
    } else {
      debouncedFetchResults(value, 1, workerId, replaceValue);
    }
    // }
    return () => {
      debouncedFetchResults.cancel();
    };
  };

  const onAgeNumberChange = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].age = e.target.value;
    setSearchSuggestionList(updatedList);
  };

  const onPhoneNumberChange = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].mobileNo = e.target.value;
    setSearchSuggestionList(updatedList);
  };

  const onselectGender = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].gender = e.target.value;
    setSearchSuggestionList(updatedList);
  };

  const onselectCaste = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].caste = e.target.value;
    setSearchSuggestionList(updatedList);
  };

  const onWhatsappNumberChange = (e) => {
    const value = e.target.value;
    const re = /^[+-]?\d*(?:[.,]\d*)?$/;

    const numericValue = value.replace(/\D/g, "");

    if (re.test(numericValue)) {
      if (numericValue.length > 10) {
        return;
      }
      setWhatsappNumber(numericValue);
    }

    if (numericValue !== null && numericValue !== "" && re.test(numericValue)) {
      if (numericValue.length < 10 || numericValue.length > 10) {
        setWhatsAppError("Please enter a valid contact number");
      } else {
        setWhatsAppError("");
      }
    } else {
      setWhatsAppError("");
    }
  };

  const checkVoterIdNumberValidation = (value) => {
    let isValid = true;
    let regex = new RegExp(/^[A-Z]{3}[0-9]{7}$/);
    if (regex.test(value) == true) {
      return (isValid = true);
    } else {
      return (isValid = false);
    }
  };


  const checkMobileNumberValidation = (value) => {
    let isValid = true;
    let regex = new RegExp(/^\d{10}$/);
    if (regex.test(value)) {
      return isValid = true;
    } else {
      return isValid = false;
    }
  };


  const handleConfirmClick = (number) => {
    if (number == showCardId) {
      setIsConfirmed(!isConfirmed);
    } else {
      setIsConfirmed(true);
    }
    setShowCardId(number);
    setWhatsAppError("");
    setIsSharedNumberCardOpen(false);
    setIsfamilyCardOpen(false);
  };

  const handleFamilyToggleCard = async (item, number) => {
    setShowCardId(number);
    setShowFamilyListLoading(true);
    const familyMemberIds = await getFamilyMember(item.id);
    onFamilyClick(item, familyMemberIds);
  };

  const sharebuttonToggleCard = (number) => {
    setShowCardId(number);
    setIsSharedNumberCardOpen(!isSharedNumberCardOpen);
    setWhatsappNumber("");
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const debouncedFetchResults = useCallback(
    _.debounce((searchValue, page, workerId, replaceValue) => {
      onNameSearchSuggestion(searchValue, page, replaceValue, '', workerId, '');
    }, 500),
    []
  );

  const onNameSearchSuggestion = (searchValue, page, replace, voterIdNumber, workerId, mobileNumber) => {
    if (isApiCallInProgress) return;

    if (voterIdNumber != "" && voterIdNumber != null) {
      setSearchSuggestionList([]);
    }
    if (mobileNumber != "" && mobileNumber != null) {
      setSearchSuggestionList([]);
    }
    setIsApiCallInProgress(true);

    let VoterName = searchValue && searchValue.trim() ? searchValue.trim().split(" ") : [""];

    setSuggestionListLoading(true);
    const data = {
      firstName: VoterName[0] == null || VoterName[0] == undefined ? "" : VoterName[0],
      middleName: VoterName[1] == null || VoterName[1] == undefined ? "" : VoterName[1],
      lastName: VoterName[2] == null || VoterName[2] == undefined ? "" : VoterName[2],
      workerId: parseInt(workerId),
      searchResultCount: 10,
      searchPageNumber: page,
      voterId: voterIdNumber == null || undefined ? "" : voterIdNumber,
      mobileNo: mobileNumber == null || undefined ? '' : mobileNumber
    };
    const selectLanguage = localStorage.getItem("selectedLanguage");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/searchsuggestions?candidateName=${name == "null" ? "" : name}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...searchSuggestionList, ...response.data.response];
          setSearchSuggestionList(searchedList);
        }
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
        setSuggestionListLoading(false);
      })
      .catch((error) => {
        setSuggestionListLoading(false);
        setIsApiCallInProgress(false);
      })
      .finally(() => {
        setLoading(false);
        setIsApiCallInProgress(false);
      });
  };
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      onShowmoreClicked();
    }
  };
  const onShowmoreClicked = () => {
    let workerId = 0;
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      workerId = decoded.Id;
    }

    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      if (voterId == "") {
        onNameSearchSuggestion(fullName, nextPageNumber, false, '', workerId, '');
      }
    }
  };
  const onChangeSelected = (language) => {
    setFirstNameLandingPage(false);
    setSelectedLanguage(language);
    localStorage.setItem("selectedLanguage", language);
    setVisible(false);
    setIsPlaying(true);
  };

  const onScanIconClick = () => {
    setShowWebCam(true);
  };

  const captureImage = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      stopWebCam();
      scanImage(imageSrc);
    }
  }, [webcamRef]);

  const onBackButtonClick = () => {
    let workerId = 0;
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      workerId = decoded.Id;
    }
    setShowWebCam(false);
    setIsConfirmed(false);
    setShowCardId("");
    setFullName("");
    setVoterId("");
    onNameSearchSuggestion('', 1, true, '', workerId, '');
  };

  const stopWebCam = () => {
    if (webcamRef.current && webcamRef.current.stream) {
      webcamRef.current.stream.getTracks().forEach((track) => track.stop());
      setIsWebCamOn(false);
    }
  };

  const scanImage = (image) => {
    const imageBase64 = image.split(",")[1];
    setScanLoadingIcon(true);
    const data = {
      imageBase64: imageBase64,
      candidateName: name,
      workerId: 0,
    };
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/scanimage`,
      data: data,
    };

    axios(config)
      .then((response) => {
        const results = response.data.response.map((item) => ({
          boothNumber: item.boothNumber,
          slipNumber: item.slipNumber,
          firstName: item.firstName,
          middleName: item.middleName,
          lastName: item.lastName,
          age: item.age,
          voterId: item.voterId,
          gender: item.gender,
          sectionNoAndName: response.data.sectionNoAndName,
          addressOfPollingStation: response.data.addressOfPollingStation,
        }));
        setSearchResultList(results);

        navigate("/searchresult", {
          state: {
            searchList: results,
            CandidateName: name,
            selectedLanguage: selectedLanguage,
          },
        });
        setScanLoadingIcon(false);
      })
      .catch((error) => {
        setScanLoadingIcon(false);
      })
      .finally(() => {
        setScanLoadingIcon(false);
      });
  };

  const videoConstraints = {
    facingMode: "environment",
  };

  const onEditVoterDetails = (voterId) => {
    setEditVoterId(voterId);
    setShowEditMode(true);
  };

  const onCancelEditVoterDetails = (voterId) => {
    setEditVoterId(voterId);
    setShowEditMode(false);
  };

  const onUpdateVoterDetails = async (item, index) => {
    let isExistsInCandidate = false;
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      isExistsInCandidate = decoded.isExistsInCandidate;
    }
    // setShowEditMode(false);
    const data = {
      id: item.id,
      WordName: item.wordName,
      slipNumber: item.slipNumber,
      boothNumber: item.boothNumber,
      firstName: item.firstName,
      middleName: item.middleName,
      lastName: item.lastName,
      fatherName: "",
      husbandName: "",
      age: item.age,
      gender: item.gender,
      district: item.district,
      taluka: item.taluka,
      town: item.town,
      addressOfPollingStation: item.addressOfPollingStation,
      voterId: item.voterId,
      workerId: 0,
      caste: item.caste,
      mobileNo: item.mobileNo,
      familyMemberCount: item.familyMemberCount,
      houseNumber: item.houseNumber,
      birthDate: item.birthDate,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/updateVoter?isExistsInCandidate=${isExistsInCandidate}`,
      data: data,
    };

    try {
      const response = await axios(config);
      if (response.data.response == true) {
        toast.current.show({ severity: "success", detail: "Details updated successfully", life: 3000 });
        setEditVoterId(null);
      } else {
        toast.current.show({ severity: "error", detail: " While Details updating", life: 3000 });
      }
    } catch (error) {
    }
  };

  const onFamilyClick = (selectedPrimaryFamilyMember, familyMemberIds) => {
    setIsfamilyCardOpen(true);
    setFamilyList([]);

    setPrimaryFamilyMemberId(selectedPrimaryFamilyMember.id);
    const data = {
      firstName: selectedPrimaryFamilyMember.firstName,
      middleName: selectedPrimaryFamilyMember.middleName,
      lastName: selectedPrimaryFamilyMember.lastName,
      workerId: 0,
      voterId: selectedPrimaryFamilyMember.voterId,
    };
    const selectLanguage = localStorage.getItem("selectedLanguage");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/searchfamilymember?candidateName=${name == "null" ? "" : name}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        let familyList = [];
        let updatedFamilyMemberList = [];
        response.data.response.forEach((item) => {
          familyList.push({
            familyInfo: item,
            isFamilyMemberCheck: false,
          });
        });
        const idSet = new Set(familyMemberIds);
        updatedFamilyMemberList = familyList.map((item) => {
          if (idSet.has(item.familyInfo.id)) {
            return { ...item, isFamilyMemberCheck: true };
          }
          return item;
        });
        setFamilyList(updatedFamilyMemberList);
        setShowFamilyListLoading(false);
      })
      .catch((error) => {
        setShowFamilyListLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFamilyMember = async (familyMemberId) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/getfamilymember?primaryFamilyMemberId=${familyMemberId}`,
    };

    try {
      const response = await axios(config);
      return response.data.response;
    } catch (error) {
    }
  };

  const onAddFamilyClick = () => {
    setAddingFamilyLoading(true);
    let selectedFamilyListIds = [];
    familyList.forEach((element) => {
      if (element.isFamilyMemberCheck == true) {
        selectedFamilyListIds.push(element.familyInfo.id);
      }
    });
    const data = {
      primaryFamilyMemberId: primaryFamilyMemberId,
      fmailyMemberId: selectedFamilyListIds,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/addfamilymember`,
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.response == true) {
          toast.current.show({ severity: "success", detail: "Family updated successfully", life: 3000 });
          setEditVoterId(null);
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error  while adding family member", life: 3000 });
        }
        setAddingFamilyLoading(false);
      })
      .catch((error) => {
        setAddingFamilyLoading(false);
      });
  };

  const onFamilyMemberClick = (item, number) => {
    let newFamilyMemberList = { ...familyList };
    newFamilyMemberList = familyList.map((familyMemberInfo, index) => {
      if (familyMemberInfo.familyInfo.id == item.familyInfo.id) {
        if (item.isFamilyMemberCheck == false) {
          return {
            familyInfo: familyMemberInfo.familyInfo,
            isFamilyMemberCheck: true,
          };
        } else {
          return {
            familyInfo: familyMemberInfo.familyInfo,
            isFamilyMemberCheck: false,
          };
        }
      } else {
        return {
          familyInfo: familyMemberInfo.familyInfo,
          isFamilyMemberCheck: familyMemberInfo.isFamilyMemberCheck,
        };
      }
    });
    setFamilyList(newFamilyMemberList);
  };

  const getCandidate = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateHistory?SlughUrl=${name}`);
      setCandidateData(response.data); // Update state with the data
      setLoading(false); // Set loading to false
    } catch (err) {
      setLoading(false); // Set loading to false
    }
  };

  // Fetch candidate data when component mounts
  useEffect(() => {
    if (name) {
      getCandidate();
    }
  }, [name]);

  // Function to handle WhatsApp click
  const handleWhatsappClick = (selectedItem, selectedNumber, phoneNumber) => {
    setShowCardId(selectedNumber);
    console.log(selectedItem, "SEEE");
    setWhatsappID(selectedItem.id);
    const updateFamily = [];
    updateFamily.push({
      "अनु क्र": selectedItem.slipNumber,
      "ओळखपत्र क्र": selectedItem.voterId,
      "बूथ क्र": selectedItem.boothNumber,
      नाव: selectedItem.lastName + " " + selectedItem.firstName + " " + selectedItem.middleName,
      वय: selectedItem.age,
      लिंग: selectedItem.gender,
      पत्ता: selectedItem.wordName + " " + selectedItem.district,
      "मतदार केंद्र": selectedItem.addressOfPollingStation,
    });
    familyList.forEach((member) => {
      if (member.isFamilyMemberCheck === true) {
        updateFamily.push({
          "अनु क्र": member.familyInfo.slipNumber,
          "ओळखपत्र क्र": member.familyInfo.voterId,
          "बूथ क्र": member.familyInfo.boothNumber,
          नाव: member.familyInfo.lastName + " " + member.familyInfo.firstName + " " + member.familyInfo.middleName,
          वय: member.familyInfo.age,
          लिंग: member.familyInfo.gender,
          पत्ता: member.familyInfo.wordName + " " + member.familyInfo.district,
          "मतदार केंद्र": member.familyInfo.addressOfPollingStation,
        });
      }
    });

    const messageArray = updateFamily
      .map((member) => {
        const srNo = `अनु क्र: ${member["अनु क्र"]}`;
        const voterIdNumber = `ओळखपत्र क्र: ${member["ओळखपत्र क्र"]}`;
        const boothNumber = `बूथ क्र: ${member["बूथ क्र"]}`;
        const fullName = `नाव: ${member.नाव}`;
        const age = `वय: ${member.वय}`;
        const gender = `लिंग: ${member.लिंग}`;
        const address = `पत्ता: ${member.पत्ता}`;
        const boothName = `मतदार केंद्र: ${member["मतदार केंद्र"]}`;

        return `
${srNo},
${voterIdNumber},
${boothNumber},
${fullName},
${age},
${gender},
${address},
${boothName}
------------------------------------`;
      })
      .filter((message) => message.length > 0);

    const votingDateTime = `\nमतदानाची तारीख आणि वेळ : २४/१०/२०२४ स. ७.३० ते सायं. ५.३०`;

    const combinedMessage = `${messageArray} ${votingDateTime}`;
    const imageMessage = "हाताच्या चिन्हासमोरील बटन दाबून \n श्रीमती जयश्रीताई मदनभाऊ पाटील \n यांना प्रचंड बहुमताने निवडून द्या !"
      ? `हाताच्या चिन्हासमोरील बटन दाबून \n श्रीमती जयश्रीताई मदनभाऊ पाटील \n यांना प्रचंड बहुमताने निवडून द्या !`
      : "";
    const fullMessage = ` ${imageMessage}  ${combinedMessage}`;

    const encodedMessage = encodeURIComponent(fullMessage);

    if (phoneNumber && phoneNumber.length === 10) {
      window.open(`https://wa.me/91${phoneNumber}?text=${encodedMessage}`, "_self");
    }
  };

  // Function to open the dialog and set the selected item and number
  const openDialog = (selectedItem, selectedNumber) => {
    setSelectedItem(selectedItem);
    setSelectedNumber(selectedNumber);
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const handleSubmit = () => {
    if (whatsappNumber.length === 10) {
      handleWhatsappClick(selectedItem, selectedNumber, whatsappNumber);
      saveElectrolMarathiHistoryData(selectedItem.id);
      closeDialog(); // Close the dialog after submission
    } else {
      alert("Please enter a valid 10-digit phone number");
    }
  };

  const ElectrolMarathiHistoryData = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/ElectrolMarathiHistory`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res)
      .catch((err) => err);
  };

  const saveElectrolMarathiHistoryData = (Id) => {
    let data = JSON.stringify({
      id: Id,
      WANumber: whatsappNumber,
    });

    ElectrolMarathiHistoryData(data).then((res) => {
      if (res.data) {
        if (res.data.statusCode === 401) {
          toast.current?.show({
            severity: "error",
            summary: res.data.message,
            life: 3000,
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Something went wrong, please contact your admin",
          life: 3000,
        });
      }
    });
  };

  return (
    <div className="app">
      <Toast ref={toast} />
      {showFirstLandingPage ? (
        <Dialog
          visible={visible}
          style={{ width: "100vw", height: "100vh", background: "#000" }}
          className="popup-lang"
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
        >
          {candidateData ? (
            <>
            <div className="col-12 col-md-4 mainbannerdiv">
              <img src={candidateData?.banner} alt="" className="dialog-img" />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }} className="mt-2">
                <img src={candidateData?.photo} alt="" className="photowithhand p-1" style={{ maxWidth: "80%" }} />
              </div>
            </>
          ) : null}
          <div className="d-flex" style={{ display: "flex", justifyContent: "center" }}>
            <img src={Namskar} alt="Whatsapp" className="namskar-img" />
          </div>

          <div className="digital-lang-text mt-1">
            डिजिटल मतदार सुविधा सेवांमध्ये <br />
            आपले स्वागत आहे.
          </div>
          <span className="text-success mb-2 text-16 panja-text">हाताच्या चिन्हासमोरील बटन दाबून</span>
          <span className="text-12">
            {candidateData?.firstName} {candidateData?.middleName && candidateData.middleName + " "}
            {candidateData?.lastName}
            <br /> यांना प्रचंड बहुमताने निवडून द्या !
          </span>
          <div className="row text-center mt-1">
            <div className="col-12 ellipse-div"  >
              <img src={candidateData?.logo} alt="Logo" style={{height:"90px"}}/>
              <img src={Ellipsis} alt="Ellipsis" style={{height:"30px"}}></img>

              <img src={btnimg} className="show-cursor" onClick={() => onChangeSelected("Marathi")} alt="Change Language" />
            </div>

            {/* <button className='btn btn-normal continue-btn mt-1' onClick={() => onChangeSelected('Marathi')}>Search </button> */}
            <div className="col-12">
              <div className="social-media-icons" style={{ marginTop: "20px" }}>
                <a href={candidateData?.twitter} target="_blank" rel="noopener noreferrer" className="p-mr-2">
                  <i className="pi pi-twitter" style={{ fontSize: "24px", marginRight: "20px", cursor: "pointer", color:"white"}}></i>
                </a>
                <a href={candidateData?.faceBook} target="_blank" rel="noopener noreferrer" className="p-mr-2">
                  <i className="pi pi-facebook" style={{ fontSize: "24px", marginRight: "20px", cursor: "pointer", color:"white" }}></i>
                </a>
                <a href={candidateData?.youTube} target="_blank" rel="noopener noreferrer">
                  <i className="pi pi-youtube" style={{ fontSize: "24px", marginRight: "20px", cursor: "pointer", color:"white" }}></i>
                </a>
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <>
          {scanLoadingIcon ? (
            <>
              <div className="row scan-loading-icon">
                <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
              </div>
            </>
          ) : (
            <>
              {showWebCam ? (
                <>
                  <Webcam
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className="scan-camera-height"
                    videoConstraints={{
                      ...videoConstraints,
                      facingMode,
                    }}
                  />
                  <div className="row p-2 mt-2 scan-btn-position">
                    <div className="col-12 d-flex justify-content-evenly">
                      <button className="btn btn-normal  continue-btn w-50 me-2" onClick={() => captureImage()}>
                        {" "}
                        Scan{" "}
                      </button>
                      <button className="btn btn-normal cancel-btn w-50" onClick={onBackButtonClick}>
                        {" "}
                        Cancel{" "}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="header header-dashboard">
                    <div className="row desktop-img">
                      <div className="col-3">
                        <img src={photo} alt="Scanner image" />
                      </div>
                      <div className="col-6 header-middle-title">
                        <div className="smaller-text">२८२ सांगली विधानसभा मतदार सांगतील कौग्रेस पक्षाचे अधिकृत उमेदवार </div>
                        <div className="large-text">
                          श्रीमती जयश्रीताई मदनभाऊ पाटील यांना <br />
                          प्रचंड बहुमताने निवडून द्या !
                        </div>
                      </div>
                      <div className="col-3 text-end" style={{ padding: "0px" }}>
                        {" "}
                        <img src={headerIcon} alt="Scanner image" style={{ marginRight: "13px" }} />{" "}
                      </div>
                    </div>
                    <img src={newdashboardbanner} alt="Scanner image" className="newdashboardbanner" />
                  </div>
                  <div className="container-fluid main-container">
                    <div className="row mt-1">
                      <div className="col-8"></div>
                      <div className="col-4 sound-btn text-end">
                        <img
                          src={isPlaying ? Soundimage : muteicon}
                          alt={isPlaying ? "Pause sound" : "Play sound"}
                          onClick={togglePlay}
                          className="audio-img"
                        />
                      </div>
                    </div>
                    <div className="row mt-2 mx-auto text-12">
                      <div className="col-12 p-0 mt-2 ">
                        <input
                          type="text"
                          className="form-control input-box"
                          id="voterIdInput"
                          placeholder="Voter ID नंबर टाका"
                          onChange={(e) => {
                            onChangeVoterId(e.target.value);
                          }}
                          value={voterId}
                        />
                        <span className="text-danger text-12 ">{errorMessage}</span>
                      </div>
                      <div className="col-12 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control input-box"
                          id="nameInput"
                          placeholder="आडनाव  नाव  मधले नाव "
                          onChange={(e) => {
                            onChangeFirstName(e.target.value);
                          }}
                          value={fullName}
                        />
                      </div>
                      <div className='col-12 p-0 mt-2 '  >
                        <input type="number" className="form-control input-box" id="mobileNoInput" placeholder="मोबाइल नंबर टाका" onChange={(e) => { onChangeMobileNo(e.target.value) }} value={mobileNo} />
                        <span className='text-danger text-12 '>{errorMessageForMobileNo}</span>
                      </div>
                      <div
                        className="col-12  card scanner-card show-cursor mt-2"
                        onClick={() => {
                          onScanIconClick();
                        }}
                      >
                        <img
                          src={cameraicon}
                          alt="Scanner image"
                          className="camera-icon-size"
                          onClick={() => {
                            onScanIconClick();
                          }}
                        />
                        <span className="scan-card-text"> &nbsp; Voter ID (ओळखपत्र) स्कॅन करा</span>
                      </div>
                    </div>

                    <BottomScrollListener onBottom={onShowmoreClicked}>
                      {(scrollRef) => (
                        <div
                          className="mt-2 scrollable-div "
                          ref={scrollRef}
                          style={{ overflowY: "auto", overflowX: "hidden" }}
                          onScroll={handleScroll}
                        >
                          {searchSuggestionList.map((item, number) => (
                            <div
                              className={
                                searchSuggestionList.length == number + 1 && suggestionListLoading == false
                                  ? " card voter-info-card last-card-bottom"
                                  : "card voter-info-card"
                              }
                              key={number}
                            >
                              <div className="row d-flex align-items-center w-100 mx-auto font-size-rem ">
                                {/* <div className="col-12 d-flex justify-content-end text-12 ">
                            {showEditMode && editVoterId == item.voterId ?
                              <>
                                <i class="pi pi-save show-cursor me-1 text-success " title="Save" onClick={() => onUpdateVoterDetails(item, number)}></i>
                                <i class="pi pi-times show-cursor text-danger " title="Cancel" onClick={() => onCancelEditVoterDetails(number)}></i>
                              </>
                              :
                              <>
                                {localStorage.getItem('token') !== null && localStorage.getItem('token') != "" ?
                                  <i class="pi pi-pencil show-cursor text-info" title='Edit' onClick={() => onEditVoterDetails(item.voterId)}></i> : null

                                }</>}
                          </div> */}
                                <div className="col-3">
                                  <label> अनु क्र.</label>
                                </div>
                                <div className="col-3 ps-0">
                                  {" "}
                                  <h6>{item.slipNumber}</h6>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-3">
                                  <label> बूथ क्र.</label>
                                </div>
                                <div className="col-2 ps-0">
                                  <h6>{item.boothNumber}</h6>
                                </div>

                                <div className="col-3 ps-0 text-end">
                                  <label>Voter ID</label>
                                </div>
                                <div className="col-4 ps-0">
                                  <h6>{item.voterId}</h6>
                                </div>

                                <div className="col-3">
                                  <label>नाव</label>
                                </div>
                                <div className="col-9 ps-0 ">
                                  <h6>
                                    {item.lastName} {item.firstName} {item.middleName}
                                  </h6>
                                </div>

                                <div className="col-3">
                                  <label>वय</label>
                                </div>
                                <div className="col-2 ps-0">
                                  {showEditMode && editVoterId === item.voterId ? (
                                    <input
                                      type="number"
                                      className="w-100 edit-input mb-1 "
                                      value={item.age}
                                      name="age"
                                      onChange={(e) => onAgeNumberChange(e, number)}
                                    />
                                  ) : (
                                    <h6>{item.age}</h6>
                                  )}
                                </div>
                                <div className="col-3 text-end">
                                  <label className="">लिंग</label>
                                </div>
                                <div className="col-4 ps-0">
                                  {showEditMode && editVoterId === item.voterId ? (
                                    <select
                                      name="Gender"
                                      value={item.gender || ""}
                                      className="form-control mb-1"
                                      id="Gender"
                                      onChange={(e) => onselectGender(e, number)}
                                    >
                                      <option></option>
                                      <option value="महिला">महिला</option>
                                      <option value="पुरुष">पुरुष</option>
                                    </select>
                                  ) : (
                                    <h6>{item.gender}</h6>
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-3">
                                    <label>पत्ता</label>
                                  </div>
                                  <div className="col-9 ps-0">
                                    <h6 className="ps-1">
                                      {item.wordName}, {item.district}
                                    </h6>
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-3 ">
                                    <label>मतदार केंद्र</label>
                                  </div>
                                  <div className="col-9 ps-0  ">
                                    <h6 className="ps-1">{item.addressOfPollingStation}</h6>
                                  </div>
                                </div>
                                {showEditMode && editVoterId == item.voterId && (
                                  <>
                                    <div className="col-4">
                                      <label>फोन नंबर</label>
                                    </div>
                                    <div className="col-8 ps-0" style={{ paddingLeft: "0px", paddingRight: "28px" }}>
                                      {showEditMode && editVoterId == item.voterId ? (
                                        <input
                                          type="number"
                                          className="w-100  edit-input mb-1"
                                          value={item.mobileNo}
                                          name="mobileNo"
                                          onChange={(e) => onPhoneNumberChange(e, number)}
                                        />
                                      ) : (
                                        <h6>{item.mobileNo}</h6>
                                      )}
                                    </div>

                                    <div className="col-4">
                                      <label>जात</label>
                                    </div>
                                    <div className="col-8 ps-0" style={{ paddingLeft: "0px", paddingRight: "28px" }}>
                                      {showEditMode && editVoterId == item.voterId ? (
                                        <select
                                          name="Caste"
                                          value={item.caste || ""}
                                          className="form-control"
                                          id="Caste"
                                          onChange={(e) => onselectCaste(e, number)}
                                        >
                                          <option>जात निवडा</option>
                                          <option value="हिंदू">हिंदू</option>
                                          <option value="शीख">शीख</option>
                                          <option value="जैन">जैन</option>
                                          <option value="बौद्ध">बौद्ध</option>
                                          <option value="मुस्लिम">मुस्लिम</option>
                                          <option value="ख्रिश्चन">ख्रिश्चन</option>
                                        </select>
                                      ) : (
                                        <h6>{item.caste}</h6>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>

                              {isFamilyCardOpen &&
                                number == showCardId &&
                                familyList.map((item, number) => (
                                  <>
                                    <div className="add-family-card">
                                      <div className="row d-flex align-items-center mx-auto w-100 py-2 dashed-border font-size-rem ">
                                        <div className="col-3">
                                          <label> अनु क्र.</label>
                                        </div>
                                        <div className="col-2 ps-0">
                                          {" "}
                                          <h6>{item.familyInfo.slipNumber}</h6>
                                        </div>
                                        <div className="col-4 text-end">
                                          <label className="text-success text-14"> कुटूंब</label>
                                        </div>
                                        <div className="col-2 ">
                                          <InputSwitch
                                            className="family-switch"
                                            checked={item.isFamilyMemberCheck ? true : false}
                                            onChange={() => onFamilyMemberClick(item, number)}
                                          />{" "}
                                          {/* <input className="form-check-input" type="checkbox" checked={item.isFamilyMemberCheck ? true : false} value="" id="flexCheckDefault" onClick={() => onFamilyMemberClick(item, number)} /> */}
                                        </div>
                                        <div className="col-1 d-flex justify-content-center text-12 "></div>

                                        <div className="col-3">
                                          <label> बूथ क्र.</label>
                                        </div>
                                        <div className="col-2 ps-0">
                                          <h6>{item.familyInfo.boothNumber}</h6>
                                        </div>
                                        <div className="col-3 ps-0 text-end">
                                          <label>Voter ID</label>
                                        </div>
                                        <div className="col-4 ps-0">
                                          <h6>{item.familyInfo.voterId}</h6>
                                        </div>
                                        <div className="col-3">
                                          <label>नाव</label>
                                        </div>
                                        <div className="col-9 ps-0">
                                          <h6>
                                            {item.familyInfo.lastName} {item.familyInfo.firstName} {item.familyInfo.middleName}
                                          </h6>
                                        </div>
                                        <div className="col-3">
                                          <label>वय</label>
                                        </div>
                                        <div className="col-2 ps-0 ">
                                          {showEditMode && editVoterId === number ? (
                                            <input
                                              type="number"
                                              className="w-100"
                                              value={item.familyInfo.age}
                                              name="age"
                                              onChange={(e) => onAgeNumberChange(e, number)}
                                            />
                                          ) : (
                                            <h6>{item.familyInfo.age}</h6>
                                          )}
                                        </div>
                                        <div className="col-3 text-end">
                                          <label>लिंग</label>
                                        </div>
                                        <div className="col-4 ps-0">
                                          {showEditMode && editVoterId === number ? (
                                            <select name="Gender" className="form-control" id="Gender" onChange={(e) => onselectGender(e, number)}>
                                              <option value="Female">स्त्री</option>
                                              <option value="Male">पुरुष</option>
                                            </select>
                                          ) : (
                                            <h6>{item.familyInfo.gender}</h6>
                                          )}
                                        </div>
                                        <div className="row">
                                          <div className="col-3">
                                            <label>पत्ता</label>
                                          </div>
                                          <div className="col-9 ps-0">
                                            <h6 className="ps-1">
                                              {item.familyInfo.wordName}, {item.familyInfo.district}
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="row ">
                                          <div className="col-3">
                                            <label>मतदार केंद्र</label>
                                          </div>
                                          <div className="col-9 ps-0">
                                            <h6 className="ps-1">{item.familyInfo.addressOfPollingStation}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}

                              <div className="row mx-auto w-100 " style={{ marginTop: "10px" }}>
                                <div className="col-4 p-1">
                                  <button type="button" className="btn btn-primary confirm-btn d-flex" onClick={() => handleConfirmClick(number)}>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      checked={number == showCardId && isConfirmed ? true : false}
                                      id="flexCheckDefault"
                                      style={{ marginTop: "4px", marginRight: "4px" }}
                                    />
                                    <div style={{ marginTop: "2px" }}>बरोबर आहे</div>
                                  </button>
                                </div>

                                {showFamilyListLoading && number === showCardId ? (
                                  <div className="col-4 d-flex justify-content-center">
                                    <ThreeDots color="#fff" height={30} width={30} />
                                  </div>
                                ) : (
                                  <>
                                    {isFamilyCardOpen && number === showCardId ? (
                                      addingFamilyLoading && number === showCardId ? (
                                        <div className="col-4 d-flex justify-content-center">
                                          <ThreeDots color="#fff" height={30} width={30} />
                                        </div>
                                      ) : familyList.length === 0 ? (
                                        <div className="col-4 p-0 d-flex align-items-center">
                                          <span className=" text-info oops-msg-text text-danger">
                                            <b>कुटुंबातील सदस्य सापडत नाही.</b>
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="col-4 p-1">
                                          <button type="button" className="btn btn-primary confirm-btn d-flex" onClick={onAddFamilyClick}>
                                            <img src={familyicon} alt="Share" className="white-wp-icon" style={{ marginTop: "2px" }} />
                                            कुटुंब जोडा
                                          </button>
                                        </div>
                                      )
                                    ) : (
                                      <div className="col-4 p-1">
                                        <button
                                          type="button"
                                          className="btn btn-primary confirm-btn d-flex"
                                          onClick={() => handleFamilyToggleCard(item, number)}
                                          disabled={!(number === showCardId && isConfirmed)}
                                        >
                                          <img src={familyicon} alt="Share" className="white-wp-icon" style={{ marginTop: "2px" }} />
                                          <div style={{ marginTop: "2px" }}>कुटुंब</div>
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}

                                <div className="col-4 p-1">
                                  <button
                                    type="button"
                                    className={`btn btn-primary d-flex ${number === showCardId && isConfirmed ? "confirm-btn success" : "confirm-btn"
                                      }`}
                                    onClick={() => openDialog(item, number)} // Open dialog and pass item, number
                                    disabled={number === showCardId && isConfirmed ? false : true}
                                  >
                                    <img src={WhiteWPImage} alt="Share" className="white-wp-icon" />
                                    <div style={{ marginTop: "2px" }}>शेअर</div>
                                  </button>
                                </div>

                                {/* PrimeReact Dialog for capturing phone number */}
                                <Dialog
                                  header="व्हॉट्सॲप नंबर टाका"
                                  visible={showDialog}
                                  onHide={closeDialog}
                                  breakpoints={{ "960px": "75vw" }}
                                  style={{ width: "30vw" }}
                                  draggable={false}
                                >
                                  <div className="p-field">
                                    <label htmlFor="whatsappNumber">व्हॉट्सॲप नंबर:</label>
                                    <input
                                      id="whatsappNumber"
                                      type="text"
                                      value={whatsappNumber}
                                      onChange={(e) => setWhatsappNumber(e.target.value)}
                                      className="p-inputtext p-component"
                                      placeholder="10-अंकी क्रमांक प्रविष्ट करा"
                                    />
                                  </div>

                                  <div className="p-d-flex p-jc-end mt-3">
                                    <button className="p-button p-component p-button-success " onClick={handleSubmit}>
                                      सबमिट करा
                                    </button>
                                  </div>
                                </Dialog>
                              </div>
                              <div className="row mt-2">
                                <div className="col-12 voting-date-time py-2">
                                  <span className="">मतदानाची तारीख आणि वेळ : २४/१०/२०२४ स. ७.३० ते सायं. ५.३०</span>
                                </div>
                              </div>
                              {/* {number == showCardId && isSharedNumberCardOpen && (
                          <>
                            <div className="row mx-auto w-100">
                              <div className="col-12">
                                <div className="mt-2">
                                  <label style={{ fontSize: '14px' }}>फोन नंबर</label>
                                  <div className="d-flex">
                                    <input
                                      type="text"
                                      ref={inputRef}
                                      className="form-control input-box"
                                      id="voterIdInput"
                                      placeholder="फोन नंबर"
                                      style={{ borderRadius: '4px 0px 0px 4px' }}
                                      value={whatsappNumber}
                                      onChange={onWhatsappNumberChange}
                                    // onChange={(e) => setWhatsappNumber(e.target.value)}
                                    />
                                    <button className="share-number-btn" onClick={() => { handleWhatsappClick(item, number) }}>शेअर</button>
                                  </div>
                                </div>
                                <span className='text-12 text-danger mt-1'>{whatsAppError}</span>
                              </div>
                            </div>

                          </>
                        )} */}
                            </div>
                          ))}
                          {suggestionListLoading ? (
                            <div className="d-flex justify-content-center">
                              <ThreeDots color="#fff" height={30} width={30} />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </BottomScrollListener>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}