import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Toast } from "primereact/toast";
import WordName from "./WordNameList";
export default function FieldWorkerList() {
  const navigate = useNavigate();
  const toast = useRef("");
  const [candidateName, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showWordName, setShowWordName] = useState(false);
  const [wordNameList, setWordNameList] = useState([]);
  const [workerList, setWorkerList] = useState([]);
  const [boothList, setBoothNoList] = useState("");
  const [wardNameList, setWardNameList] = useState([]);
  const [wardNameListwithoutBooth, setWardNameListwithoutBooth] = useState([]);
  const [areaBoothList, setAreaBoothList] = useState([]);
  // State to track errors
  const [errors, setErrors] = useState({});
  const handleClose = () => {
    setShowModal(false);
    // setShowVoterInfo(true); // Show voter data again when the popup is closed
    getFieldWorkerList(1, true, candidateName);
  };

  const handleShowWordName = () => {
    setShowWordName(null);
    setShowWordName(true);
    setShowVoterInfo(false);
    setShowModal(false);
    // GetPollingDetailsByWordName(candidateName);
  };
  const handleCloseWordName = () => {
    setShowWordName(false);

    getFieldWorkerList(1, true, candidateName);
  };

  const handleShow = () => {
    setShowModal(true);
    setShowVoterInfo(false);
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getFieldWorkerList(1, true, candidateName);
    }
  }, []);

  const getFieldWorkerList = (pageno, replace, candidateNm) => {
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);

    const data = {
      boothAddress: "",
      pollingAddress: "",
      fromValue: 0,
      toValue: 0,
      isGetAllValue: false,
      searchPageNumber: pageno,
      searchResultCount: 10,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getworkerdetails?candidateName=${candidateNm}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
          updateVoterDetailsWithAdditionalData(candidateNm,searchedList);
        }
        setShowVoterInfo(true);
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };

  const updateVoterDetailsWithAdditionalData = (candidateName,searchedList) => {
    const url = `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetWorkerWorkDetails?candidateName=${candidateName}`
      axios.get(url)
    .then((response) => {
      const workerDetails = response.data.response; 
      if (!Array.isArray(workerDetails)) {
        return;
      }
      const updatedList = searchedList.map(voter => {
        const workerDetail = workerDetails.find(detail => detail.workerId === voter.workerId);
        if (workerDetail) {
          return {
            ...voter,
            totalVoters: workerDetail.totalVoters,
            workerVisitCount: workerDetail.workerVisitCount,
          };
        }
        return voter;
      });

      setVoterDetailsList(updatedList);
    })
    .catch((error) => {
    });
  };

  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      getFieldWorkerList(nextPageNumber, false, candidateName);
    }
  };
  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWorkerList((prevList) => ({
      ...prevList,
      [name]: value,
    }));
  };

  const getAssignAreaDetails = (boothNumber, wardName) => {
    setBoothNoList(boothNumber);
    setWardNameList(wardName);
    setShowWordName(false);
    setShowModal(true);

    let AddressOfPollingStation = [];
    let WordName = [];

    wardName.forEach((item) => {
      const parts = item.split("-");
      AddressOfPollingStation.push(parts[0]);
      WordName.push(parts.slice(1).join("-"));
    });
    setWardNameListwithoutBooth(WordName);
    setAreaBoothList(AddressOfPollingStation);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!workerList.lastName) newErrors.lastName = "आडनाव आवश्यक आहे";
    if (!workerList.firstName) newErrors.firstName = "प्रथम नाव आवश्यक आहे";
    if (!workerList.mobileNo || workerList.mobileNo.length !== 10) newErrors.mobileNo = "मोबाईल नंबर १० अंकी असावा";
    if (workerList.email && !/\S+@\S+\.\S+/.test(workerList.email)) newErrors.email = "वैध ईमेल आवश्यक आहे";
    if (!workerList.aadhaarCard) newErrors.aadhaarCard = "आधार कार्ड आवश्यक आहे";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Submit Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (validateForm()) {
    onAddOrUpdateWorkerList();
    // }
  };

  const onAddOrUpdateWorkerList = (e) => {
    // e.preventDefault();
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    let formattedWardNames = "";
    let formattedAddressOfPollingStation = "";
    if (Array.isArray(wardNameList) && Array.isArray(boothList)) {
      formattedWardNames = wardNameList.join("#");
      formattedAddressOfPollingStation = areaBoothList.join("#");
    } else {
    }

    const data = {
      firstName: workerList.firstName,
      middleName: workerList.middleName,
      lastName: workerList.lastName,
      mobile: workerList.mobile,
      mobile1: workerList.mobile1,
      AddressOfPollingStation: formattedAddressOfPollingStation,
      WordName: formattedWardNames,
      email: workerList.email,
      aadhaarCard: workerList.aadhaarCard,
      voterId: workerList.voterId,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Worker/addnewworker?candidateName=${decoded.UserName}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.response) {
          toast.current.show({ severity: "success", detail: "Worker added successfully", life: 3000 });
          handleClose();
          //   setWorkerList({
          //     lastName: "",
          //     firstName: "",
          //     middleName: "",
          //     voterId: "",
          //     mobileNo: "",
          //     mobile1: "",
          //     email: "",
          //     aadhaarCard: "",
          //     wordNameList: [], // Reset the array to empty
          //   });
        } else {
          toast.current.show({ severity: "error", detail: "Error while adding worker", life: 3000 });
        }
      })
      .catch((error) => {
        toast.current.show({ severity: "error", detail: "Unknown error occurred", life: 3000 });
      });
  };

  return (
    <div className="app main-container-login">
      <Modal show={showModal} onHide={handleClose} backdrop="static" className="modal-dark-theme fullscreen" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>नवीन कार्यकर्ता जोडा</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label className="col-md-2 col-form-label mt-2">आडनाव</label>
              <input
                type="text"
                name="lastName"
                className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                value={workerList.lastName}
                onChange={handleInputChange}
              />
              {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
            </div>

            <div className="form-group">
              <label>प्रथम नाव</label>
              <input
                type="text"
                name="firstName"
                className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
                value={workerList.firstName}
                onChange={handleInputChange}
              />
              {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
            </div>

            <div className="form-group">
              <label>मधले नाव</label>
              <input type="text" name="middleName" className="form-control" value={workerList.middleName} onChange={handleInputChange} />
            </div>

            <div className="form-group">
              <label>मतदार ओळखपत्र</label>
              <input type="text" name="voterId" className="form-control" value={workerList.voterId} onChange={handleInputChange} />
            </div>

            <div className="form-group">
              <label>मोबाईल</label>
              <input
                type="text"
                name="mobileNo"
                maxLength={10}
                className={`form-control ${errors.mobileNo ? "is-invalid" : ""}`}
                value={workerList.mobileNo}
                onChange={handleInputChange}
              />
              {errors.mobileNo && <div className="invalid-feedback">{errors.mobileNo}</div>}
            </div>

            <div className="form-group">
              <label>मोबाईल1</label>
              <input type="text" name="mobile1" className="form-control" maxLength={10} value={workerList.mobile1} onChange={handleInputChange} />
            </div>

            <div className="form-group">
              <label>ईमेल</label>
              <input
                type="text"
                name="email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                value={workerList.email}
                onChange={handleInputChange}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>

            <div className="form-group">
              <label>आधार कार्ड</label>
              <input
                type="text"
                name="aadhaarCard"
                className={`form-control ${errors.aadhaarCard ? "is-invalid" : ""}`}
                value={workerList.aadhaarCard}
                onChange={handleInputChange}
              />
              {errors.aadhaarCard && <div className="invalid-feedback">{errors.aadhaarCard}</div>}
            </div>

            <div className="row">
              <div className="col-12 text-end">
                <button
                  className="btn show-btn w-auto text-15 mt-3"
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowWordName();
                  }}
                >
                  क्षेत्र जोडा
                </button>
              </div>
            </div>

            <div className="row d-flex">
              {wardNameList.map((ward, index) => (
                <div key={index} className="col-12 ps-0 mt-2">
                  <div className="card voter-list-card text-light text-14">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <span>{ward}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="text-end mt-3">
              <button className="btn btn-secondary w-auto text-15 ml-right" onClick={handleClose}>
                रद्द करा
              </button>
              <button
                type="submit"
                className="btn btn-primary w-auto text-15"
                onClick={handleSubmit}
                // disabled={Object.keys(errors).length === 0}
              >
                 सादर करा
                {/* स्वीकारार्ह आमंत्रण पाठवा */}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showWordName} onHide={handleCloseWordName} backdrop="static" className="modal-dark-theme fullscreen" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>नवीन कार्यकर्ता जोडा</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WordName handleCallBack={getAssignAreaDetails} />
        </Modal.Body>
      </Modal>
      <Toast ref={toast} />
      <div className="header">
        <div className="row mt-2">
          <div className="col-10">
            <label htmlFor="Constituency" className="form-label Polling text-light">
              नियुक्त केलेली मतदार यादी पहा
            </label>
          </div>
          <div className="col-2 text-end">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-end">
            <button className="btn show-btn w-auto text-15 mt-3" onClick={handleShow}>
              {" "}
              नवीन कार्यकर्ता जोडा
            </button>
          </div>
        </div>
        {showVoterInfo ? (
          <BottomScrollListener onBottom={onShowmoreClicked}>
            {(scrollRef) => (
              <div className="mt-2" ref={scrollRef} style={{ overflowY: "auto", overflowX: "hidden" }}>
                {voterDetailsList.map((item, number) => (
                  <div
                    className={
                      voterDetailsList.length == number + 1 && votersListLoading == false
                        ? " card mb-2 voter-list-card last-card-bottom"
                        : "card mb-2  voter-list-card"
                    }
                    key={number}
                  >
                    <div className="row d-flex mx-auto ">
                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label"> {item.slipNumber}</span>
                      </div>
                      <div className="col-5 p-0 mb-1">
                        <span className="voter-list-answer">{item.voterId}</span>
                      </div>
                      <div className="row mb-1">
                      <div className="col-4 ps-0 text-center">
                        <div className="fw-bold voter-list-label">लक्ष्य</div>
                        <div className="voter-list-answer">{item.totalVoters ? item.totalVoters : 0}</div>
                      </div>
                      <div className="col-8 ps-0">
                        <div className="row">
                          <div className="col-6 ps-0 text-center">
                            <div className="fw-bold voter-list-label">गाठलेले लक्ष्य</div>
                            <div className="voter-list-answer">{item.workerVisitCount ? item.workerVisitCount:0 }</div>
                          </div>
                          <div className="col-6 ps-0 text-center">
                            <div className="fw-bold voter-list-label">लक्ष्य बाकी</div>
                            <div className="voter-list-answer">  {(item.totalVoters ? item.totalVoters : 0) - (item.workerVisitCount ? item.workerVisitCount : 0)}
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">नाव</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">
                          {item.lastName} {item.firstName} {item.middleName}
                        </span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">फोन नंबर</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">{item.mobileNo}</span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">पत्ता</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">{item.address}</span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">घर क्रमांक</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">{item.houseNumber}</span>
                      </div>
                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">वय</span>
                      </div>
                      <div className="col-3 ps-0 mb-1 ">
                        <span className="voter-list-answer">{item.age}</span>
                      </div>
                      <div className="col-2 mb-1">
                        <span className="fw-bold voter-list-label"> लिंग</span>
                      </div>
                      <div className="col-3 ps-0 mb-1">
                        <span className="voter-list-answer">{item.gender}</span>
                      </div>
                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">जन्मदिनांक</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">
                          {item.birthDate != "" && item.birthDate != null ? moment(item.birthDate).format("YYYY-MM-DD") : ""}
                        </span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">कुटुंब सदस्य</span>
                      </div>
                      <div className="col-3 ps-0 pe-0 mb-1 ">
                        <span className="voter-list-answer">{item.familyMemberCount}</span>
                      </div>
                      <div className="col-2 mb-1">
                        <span className="fw-bold voter-list-label">जात</span>
                      </div>
                      <div className="col-3 ps-0 mb-1">
                        <span className="voter-list-answer">{item.caste}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </BottomScrollListener>
        ) : null}

        {votersListLoading ? (
          <div className="d-flex justify-content-center">
           <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
